import React, { useEffect, useState } from "react";
import { axios_instance as axios } from "../../../boot/axios";

const IncomingRequest = () => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    await axios
      .get("/booking/incoming-request")
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Action = async (status, id) => {
    if (status) {
      console.log("True");
    } else {
      console.log("False");
    }
    console.log("Id is : " + id);
    await axios.post("/booking/booking-status", { status, id }).then((resp) => {
      if (resp.data.success) {
        console.log("Booking Updated");
      }
    });
  };

  return (
    <>
      <h5>Incoming Request</h5> <br />
      <br />
      <table>
        <tr>
          <th>Name</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Contact</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Venue</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Space Name</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Booking Date</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>From - To</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Approve</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Reject</th>
        </tr>
        {data.map((data, key) => (
          <tr>
            <td> {data.userId.name}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.userId.phone}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.venueId.title}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.venueId.vtype}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.date}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td>
              {" "}
              {data.from} - {data.to}
            </td>
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td>
              <button
                onClick={() => {
                  const status = true;
                  Action(status, data._id);
                }}
                className="btn btn-success"
              >
                Approve
              </button>
            </td>
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td>
              <button
                onClick={() => {
                  const status = false;
                  Action(status, data._id);
                }}
                className="btn btn-danger"
              >
                Reject
              </button>
            </td>
          </tr>
        ))}
      </table>
    </>
  );
};

export default IncomingRequest;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import UpdateInitialList from "./update-venue/update-initial-list";

const UpdateVenues = () => {
  const { id } = useParams();
  // console.log("Id is : " + props.match.params.id);
  // const initialList = () => {
  //   <UpdateInitialList value={props.value} />;
  // };
  return (
    <>
      <h2>
        <center> Update your Venue </center>
       <p>Hello</p>
      </h2>
    </>
  );
};

export default UpdateVenues;

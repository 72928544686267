import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { axios_instance as axios } from "../../../../boot/axios";
import { BarLoader } from "react-spinners";

const UpdateSocialAccounts = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    snapchat: "",
  });

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setData({
          facebook: resp.data.facebook,
          instagram: resp.data.instagram,
          twitter: resp.data.twitter,
          linkedin: resp.data.linkedin,
          snapchat: resp.data.snapchat,
        });
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  async function postData(e) {
    e.preventDefault();
    console.log("Json object : " + JSON.stringify(data));
    const initiaState = { ...data, id };
    try {
      axios.post("/venue/update-social-accounts", initiaState).then((resp) => {
        resp.data.success
          ? alert("Social Accounts Successfully Updated")
          : alert("Social Accounts not updated.");
      });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      {loading ? (
        <>
          <form onSubmit={postData}>
            <label for="facebook"> Facebook </label>
            <input
              type="url"
              name="facebook"
              placeholder="Enter Facebook account URl here"
              value={data.facebook}
              onChange={inputEvent}
            />
            <label for="instagram"> Instagram </label>
            <input
              type="url"
              name="instagram"
              placeholder="Enter Instagram account URl here"
              value={data.instagram}
              onChange={inputEvent}
            />
            <label for="twitter"> Twitter </label>
            <input
              type="url"
              name="twitter"
              placeholder="Enter Twitter account URl here"
              value={data.twitter}
              onChange={inputEvent}
            />
            <label for="linkedin"> linkedIn </label>
            <input
              type="url"
              name="linkedin"
              placeholder="Enter Linkedin account URl here"
              value={data.linkedin}
              onChange={inputEvent}
            />
            <label for="snapchat"> Snapchat </label>
            <input
              type="url"
              name="snapchat"
              placeholder="Enter Snapchat account URl here"
              value={data.snapchat}
              onChange={inputEvent}
            />
            <button type="submit"> Update</button>
          </form>
        </>
      ) : (
        <>
          <p> Loading.... </p>
          <BarLoader />
        </>
      )}
    </>
  );
};

export default UpdateSocialAccounts;

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import Checkbox from "./check-box";

const VenueDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [corporateItems, setCorporateItems] = useState({});
  const [weddingItems, setWeddingItems] = useState({});
  const [partyItems, setPartyItems] = useState({});
  const [lifestyleItems, setLifestyleItems] = useState({});
  const [vtype, setVenueType] = useState("");

  const corporate = [
    {
      name: "Annual Dinner",
      key: "checkBox1",
      label: "Annual Dinner",
    },
    {
      name: "BTL Activation",
      key: "checkBox2",
      label: "BTL Activation",
    },
    {
      name: "Business Meeting",
      key: "checkBox3",
      label: "Business Meeting",
    },
    {
      name: "Conference/Seminar",
      key: "checkBox4",
      label: "Conference/Seminar",
    },
    {
      name: "Networking Event",
      key: "checkBox5",
      label: "Networking Event",
    },
    {
      name: "Training Workshop",
      key: "checkBox6",
      label: "Training Workshop",
    },
    {
      name: "Product Lunch",
      key: "checkBox7",
      label: "Product Lunch",
    },
    {
      name: "Exhibition",
      key: "checkBox8",
      label: "Exhibition",
    },
    {
      name: "Media Event",
      key: "checkBox9",
      label: "Media Event",
    },
    {
      name: "Bloggers Meet",
      key: "checkBox10",
      label: "Bloggers Meet",
    },
    {
      name: "Day Out",
      key: "checkBox11",
      label: "Day Out",
    },
  ];

  const wedding = [
    {
      name: "Engagement",
      key: "checkBox1",
      label: "Engagement",
    },
    {
      name: "Nikkah Reception",
      key: "checkBox2",
      label: "Nikkah Reception",
    },
    {
      name: "Mehndi",
      key: "checkBox3",
      label: "Mehndi",
    },
    {
      name: "Barat",
      key: "checkBox4",
      label: "Barat",
    },
    {
      name: "Valima Reception",
      key: "checkBox5",
      label: "Valima Reception",
    },
  ];

  const party = [
    {
      name: "Birthday",
      key: "checkBox1",
      label: "Birthday",
    },
    {
      name: "Special Celebration",
      key: "checkBox2",
      label: "Special Celebration",
    },
    {
      name: "New Year",
      key: "checkBox3",
      label: "New Year",
    },
    {
      name: "Graduation",
      key: "checkBox4",
      label: "Graduation",
    },
    {
      name: "Anniversary",
      key: "checkBox5",
      label: "Anniversary",
    },
    {
      name: "Weekend Brunch",
      key: "checkBox6",
      label: "Weekend Brunch",
    },
    {
      name: "Bridar Shower",
      key: "checkBox7",
      label: "Bridar Shower",
    },
    {
      name: "Private Dinning",
      key: "checkBox8",
      label: "Private Dinning",
    },
    {
      name: "Iftar",
      key: "checkBox9",
      label: "Iftar",
    },
  ];

  const lifestyle = [
    {
      name: "Concert",
      key: "checkBox1",
      label: "Concert",
    },
    {
      name: "Award Show",
      key: "checkBox2",
      label: "Award Show",
    },
    {
      name: "Music Gig",
      key: "checkBox3",
      label: "Music Gig",
    },
    {
      name: "Fashion Show",
      key: "checkBox4",
      label: "Fashion Show",
    },
    {
      name: "Festival",
      key: "checkBox5",
      label: "Festival",
    },
  ];

  const venueTypes = [
    { label: "Hotel", value: "Hotel" },
    { label: "Resort/Club", vblue: "Resort/Club" },
    { label: "Banquet Hall", value: "Banquet Hall" },
    { label: "Beach House", value: "Beach House" },
    { label: "Farm House", value: "Farm House" },
    { label: "Sports Ground", value: "Sports Ground" },
    { label: "Art Gallery", value: "Art Gallery" },
    { label: "Historical Building", value: "Historical Building" },
    { label: "Exhibition Hall", value: "Exhibition Hall" },
    { label: "Auditorium/Theater", value: "Auditorium/Theater" },
    { label: "Mall", value: "Mall" },
    { label: "Cafe/Restaurant", value: "Cafe/Restaurant" },
    { label: "Meeting/Training", value: "Meeting/Training" },
    { label: "Outdoor", value: "Outdoor" },
  ];

  const corporateHandleChange = (event) => {
    setCorporateItems({
      ...corporateItems,
      [event.target.name]: event.target.checked,
    });
  };

  const weddingHandleChange = (event) => {
    setWeddingItems({
      ...weddingItems,
      [event.target.name]: event.target.checked,
    });
  };

  const partyHandleChange = (event) => {
    setPartyItems({
      ...partyItems,
      [event.target.name]: event.target.checked,
    });
  };

  const lifestyleHandleChange = (event) => {
    setLifestyleItems({
      ...lifestyleItems,
      [event.target.name]: event.target.checked,
    });
  };

  const handleVenueTypeSelect = (e) => {
    setVenueType(e.value);
  };

  async function postData(e) {
    e.preventDefault();
    const fileds = location.state;
    const state = {
      ...fileds,
      vtype,
      corporateItems,
      weddingItems,
      partyItems,
      lifestyleItems,
    };
    console.log("Venue Details : " + JSON.stringify(state));
    history.push({
      pathname: "/food-drinks",
      state,
    });
  }
  return (
    <>
      <form onSubmit={postData}>
        <Select options={venueTypes} onChange={handleVenueTypeSelect} />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        <br />
        <br />
        <h3>Corporate</h3>
        <br />
        {corporate.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={corporateItems[item.name]}
              onChange={corporateHandleChange}
            />
            <br />
          </label>
        ))}
        <br />
        <br />
        <h3>Weddings</h3>
        <br />
        {wedding.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={weddingItems[item.name]}
              onChange={weddingHandleChange}
            />
            <br />
          </label>
        ))}
        <br />
        <br />
        <h3>Party</h3>
        <br />
        {party.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={partyItems[item.name]}
              onChange={partyHandleChange}
            />
            <br />
          </label>
        ))}
        <br />
        <br />
        <h3>Life Style</h3>
        <br />
        {lifestyle.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={lifestyleItems[item.name]}
              onChange={lifestyleHandleChange}
            />
            <br />
          </label>
        ))}
        <br />
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <button type="submit"> Save and Next </button>
      </form>
    </>
  );
};
export default VenueDetails;

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Checkbox from "./check-box";

const FoodDrinks = () => {
  const location = new useLocation();
  const history = new useHistory();
  const [foodItems, setFoodItems] = useState({});

  const Food = [
    {
      name: "External Catering Allowed",
      key: "checkBox1",
      value: "External Catering Allowed",
    },
    {
      name: "In house Catering",
      key: "checkBox2",
      value: "In house Catering",
    },
    {
      name: "Caterers on Panel",
      key: "checkBox3",
      value: "Caterers on Panel",
    },
    {
      name: "In house Soft Drinks and Mineral Water",
      key: "checkBox4",
      value: "In house Soft Drinks and Mineral Water",
    },
    {
      name: "Dedicated Pantry Area",
      key: "checkBox5",
      value: "Dedicated Pantry Area",
    },
    {
      name: "Dedicated Area for Live Cooking",
      key: "checkBox6",
      value: "Dedicated Area for Live Cooking",
    },
  ];

  const foodHandleChange = (event) => {
    setFoodItems({
      ...foodItems,
      [event.target.name]: event.target.checked,
    });
  };

  async function postData(e) {
    e.preventDefault();
    const fields = location.state;
    const state = { ...fields, foodItems };
    console.log("Food and Drinks : " + JSON.stringify(state));
    history.push({
      pathname: "/facility-safety",
      state,
    });
    // console.log("Description: " +  description + " From: " + from + " To: " + to + " Hours: " + hours + " Length: " +
    // length + " Width: " + width + " Height: " + height + " Time: " + time + " Slot: " + slot + " Seating: "+ seating + " Standing: " + standing + " Minimum: "+ minimum);
  }
  return (
    <>
      <form onSubmit={postData}>
        <h6> Food and Drinks </h6>
        {Food.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={foodItems[item.name]}
              onChange={foodHandleChange}
            />
            <br />
          </label>
        ))}
        <button type="submit"> Save & Next</button>
      </form>
    </>
  );
};

export default FoodDrinks;

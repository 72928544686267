import React from "react";
import { useHistory, useLocation } from "react-router";

const ConfirmBooking = () => {
  const history = useHistory();
  const Queries = () => {
    history.push("/booking-queries");
  };
  return (
    <>
      <h1> Thank You For Booking </h1>
      <br />
      <br />
      <br />
      <button type="button" onClick={Queries}>
        My Queries
      </button>
    </>
  );
};
export default ConfirmBooking;

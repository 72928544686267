import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getSelected } from "./helper";
import Checkbox from "./check-box";

const VenueCapacity = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({ seating: 0, standing: 0, minimum: 0 });
  const [styleItems, setStyleItems] = useState({}); //plain object as state

  const checkboxes = [
    {
      name: "Standing",
      key: "checkBox1",
      label: "Standing",
    },
    {
      name: "Sofa Lounges",
      key: "checkBox2",
      label: "Sofa Lounges",
    },
    {
      name: "U-Shape",
      key: "checkBox3",
      label: "U-Shape",
    },
    {
      name: "Hollow Square",
      key: "checkBox4",
      label: "Hollow Square",
    },
    {
      name: "Board Room",
      key: "checkBox5",
      label: "Board Room",
    },
    {
      name: "Classroom",
      key: "checkBox6",
      label: "Classroom",
    },
    {
      name: "Theater",
      key: "checkBox7",
      label: "Theater",
    },
    {
      name: "Stadium",
      key: "checkBox8",
      label: "Stadium",
    },
    {
      name: "Caberet Style",
      key: "checkBox9",
      label: "Caberet Style",
    },
    {
      name: "Banquet",
      key: "checkBox10",
      label: "Banquet",
    },
  ];

  const handleChange = (event) => {
    setStyleItems({
      ...styleItems,
      [event.target.name]: event.target.checked,
    });
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    // console.log("Data is given below: ");
    // console.log("Description: " +  description + " From: " + from + " To: " + to + " Hours: " + hours + " Length: " +
    // length + " Width: " + width + " Height: " + height + " Time: " + time + " Slot: " + slot + " Seating: "+ data.seating + " Standing: " + data.standing + " Minimum: "+ data.minimum);
  };

  async function postData(e) {
    e.preventDefault();
    const fields = location.state;
    const state = { ...fields, ...data, styleItems };
    console.log("Venue Capacity : " + JSON.stringify(state));
    history.push({
      pathname: "/venue-details",
      state,
    });
  }

  return (
    <form onSubmit={postData}>
      <h6> Capacity </h6>
      <label for="seating"> Seating Capacity</label>
      <input
        id="seating"
        name="seating"
        value={data.seating}
        onChange={inputEvent}
        type="number"
      />
      <label for="standing"> Standing Capacity </label>
      <input
        id="standing"
        name="standing"
        value={data.standing}
        onChange={inputEvent}
        type="number"
      />
      <label for="minimum"> Minimum Capacity </label>
      <input
        id="minimum"
        name="minimum"
        value={data.minimum}
        onChange={inputEvent}
        type="number"
      />
      <h6> Layout Style </h6>
      {checkboxes.map((item) => (
        <label key={item.key}>
          {item.name}
          <Checkbox
            name={item.name}
            checked={styleItems[item.name]}
            onChange={handleChange}
          />
          <br />
        </label>
      ))}
      <button type="submit"> Next </button> <hr />
    </form>
  );
};

export default VenueCapacity;

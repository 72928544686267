import React, { useState } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  async function postData(e) {
    try {
      e.preventDefault();
      await axios
        .post("/api/auth/agent/forgot-password", { email })
        .then((response) => {
          if (response.data.success) {
            history.push("/forgot-code-verification");
          }
        })
        .catch((err) => {
          console.log("Error while accessing data" + err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3> Email</h3> <br />
          <Form onSubmit={postData}>
            <TextField
              label="Enter your email"
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Continue
            </Button>
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default ForgotPassword;

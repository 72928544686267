import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import GoogleMaps from "../google-map/google-map";

const MapDirection = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.initialState;
  const search = state.area + ", " + state.city + ", " + state.country;
  async function postData(e) {
    console.log(JSON.stringify(state));
    e.preventDefault();
    history.push({
      pathname: "/social-accounts",
      state,
    });
  }
  return (
    <>
      <form onSubmit={postData}>
        <label> Your Space Location </label>
        <input type="text" id="location" value={search} name="search" />
        <button type="submit"> Next </button>
      </form>
      <br />
      <br />
      <GoogleMaps />
    </>
  );
};

export default MapDirection;

import React, { useState } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Form } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import "../user-login/user-login.css";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory, useLocation } from "react-router-dom";

const SetPassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const location = useLocation();
  const response = location.state.data;

  async function postData(e) {
    const userId = response.userID;
    const name = response.name;
    e.preventDefault();
    try {
      await axios
        .post("/api/auth/user/fb-login", {
          userId,
          name,
          password,
        })
        .then((response) => {
          if (response.data.success) {
            history.push("/home");
          }
        });
    } catch (error) {
      document.getElementById("danger").style.display = "block";
    }
  }

  async function postResponse(e) {
    const userId = response.userID;
    const name = response.name;
    // console.log(userId + " | " + name + " | " + email + " | " + pic);
    e.preventDefault();
    try {
      await axios
        .post("/api/auth/user/fb-login", {
          userId,
          name,
        })
        .then((response) => {
          // console.log("Response is here Zain: " + response.data.success);
          if (response.data.successStatus == 1) {
            history.push("/contact");
          } else {
            history.push("/home");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyling">
          <h3> Set Password</h3>
          <div
            className="alert alert-danger"
            id="danger"
            style={{ display: "none" }}
          >
            <strong>Error!</strong> Password is incorrect.
          </div>
          <Form onSubmit={postData}>
            <TextField
              type="hidden"
              name="response"
              value={response}
              fullWidth
              required
            />
            <TextField
              label="Enter password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter password"
              fullWidth
              required
            />
            <FormControlLabel
              control={<Checkbox name="checkedB" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Login
            </Button>
          </Form>
          <Button
            onClick={postResponse}
            color="primary"
            variant="contained"
            style={{ margin: "8px 0" }}
            fullWidth
          >
            Continue
          </Button>
        </Paper>
      </Grid>
    </>
  );
};

export default SetPassword;

import { useLocation } from "react-router";
import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField, Button, Input } from "@material-ui/core";
import "../signup/signup.css";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { axios_instance as axios } from "../../../boot/axios";

const UpdateBrand = () => {
  const location = useLocation();
  const details = location.post;
  const [logo, setLogo] = useState(null);


  const [brand, setBrand] = useState({
    name: details.name,
    email: details.email,
    phone: details.phone,
    address: details.address,
    // logo: "",
    website: details.website,
  });

  //   console.log("Details : " + details.name);
  const history = useHistory();
  //   const [data, setData] = useState({
  //     name: "",
  //     email: "",
  //     phone: "",
  //     address: "",
  //     logo: "",
  //     website: "",
  //   });

  useEffect(async () => {
    // async function fetchData() {
    //   await axios
    //     .get("/venue/brand_id/" + id)
    //     .then((resp) => {
    //       // console.log("Response is : " + JSON.stringify(resp.data));
    //       console.log("Data : " + JSON.stringify(resp.data));
    //       setBrand({
    //         name: resp.data.name,
    //         email: resp.data.email,
    //         phone: resp.data.phone,
    //         address: resp.data.address,
    //         // logo: resp.data.logo,
    //         website: resp.data.website,
    //       });
    //     })
    //     .catch((err) => console.log(err));
    // }
    // fetchData();
    // console.log(brand.name);
    // console.log(brand);
    // setName(brand.name);
    // setEmail(brand.email);
    // setPhone(brand.phone);
    // setAddress(brand.address);
    // setLogo(brand.logo);
    // setWebsite(brand.website);
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setBrand((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setLogo(event.target.files[0]);
  };

  async function postData(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", logo);
    try {
      await axios
        .post("/venue/save-file", formData, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    const path = logo.name;
    try {
      const data = {...brand, path}
      await axios
        .post("/venue/update-brand", {
          data,
        })
        .then(() => {
          history.push("/agent-home");
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3> Update Brand</h3>
          <Form onSubmit={postData}>
            <TextField
              label="Enter your name"
              type="text"
              //   placeholder="Enter your name"
              name="name"
              value={brand.name}
              onChange={inputEvent}
              fullWidth
              required
            />{" "}
            <br />
            <br />
            <TextField
              label="Enter email"
              type="email"
              //   placeholder="Enter email"
              name="email"
              value={brand.email}
              onChange={inputEvent}
              fullWidth
              required
            />
            <br />
            <br />
            <TextField
              label="Enter phone"
              type="tel"
              //   placeholder="Enter phone"
              name="phone"
              value={brand.phone}
              onChange={inputEvent}
              fullWidth
            />
            <br />
            <br />
            <TextField
              label="Enter address"
              type="text"
              //   placeholder="Enter address"
              name="address"
              value={brand.address}
              onChange={inputEvent}
              fullWidth
            />{" "}
            <br />
            <br />
                      <input type="file" name="file" onChange={onChangeHandler} />

            <br />
            <br />
            <TextField
              label="Enter website url"
              type="url"
              //   placeholder="Enter website url"
              name="website"
              value={brand.website}
              onChange={inputEvent}
              fullWidth
            />{" "}
            <br />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Update
            </Button>{" "}
            <br />
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default UpdateBrand;

import { ClassNames } from "@emotion/react";
import React from "react";
import { styled } from "@material-ui/system";
import "./explore-venue.css";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
//  {/* <h2> {data.title} </h2> */}

const Explore = ({ posts }) => {
  const history = useHistory();
  // const venuePrfile = () => {
  //   history.push({
  //     pathname: "/venue-profile",
  //     posts,
  //   });
  // };
  return (
    <>
      {posts.map((data, key) => (
        <div class="card mb-4">
          <a class="img-card" href="#">
            <small class="white front tiny">
              {data.from} - {data.to}
              <br />
              Timing
            </small>
            <div class="review-card">
              <i class="fas fa-star"></i> <i class="fas fa-star"></i>
              <i class="fas fa-star"></i> <i class="fas fa-star"></i>
              <i class="fas fa-star-half"></i>
              <span class="tiny white">2 reviews</span>
            </div>
            <div class="bottom-tour-background"></div>
            <img src="assets/images/china.jpg" alt="image" />
          </a>
          <div class="card-content">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="black mb-2">
                  <a href="#" target="_blank">
                    {data.title}
                  </a>
                </h6>
              </div>
              <div class="col-4 align-middle">
                <h6 class="primary-color text-right">{data.price}</h6>
              </div>
              <div>
                <p>
                  &nbsp;&nbsp;
                  <i class="far fa-building"></i>&nbsp;&nbsp;{data.vtype}
                </p>
                <p>
                  &nbsp;&nbsp;
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  &nbsp;&nbsp;
                  {data.country + ", " + data.city + ", " + data.area}
                </p>
                <p>
                  &nbsp;&nbsp;
                  <i class="fas fa-male"></i>&nbsp;&nbsp;{" "}
                  {"Seating " + data.seating + " / Standing " + data.standing}
                </p>
                {/* <a
                  class="btn btn-primary px-3 ml-3 mr-1 my-1 btn-sm float-left"
                  href="#"
                  role="button"
                  style={{ width: "auto" }}
                >
                  View Profile
                </a> */}
                <Link
                  class="btn btn-primary px-3 btn-sm float-left"
                  style={{ width: "auto" }}
                  to={"/venue-profile/" + data._id}
                >
                  View Profile
                </Link>
                {/* <a
                class="btn btn-primary mx-1 px-3 mx-2 my-1 btn-sm float-left"
                href="#"
                role="button"
              >
                travel
              </a> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Explore;

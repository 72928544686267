import { axios_instance as axios } from "../../../boot/axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import UpdateVenue from "./update-venues";

const MoreDetails = () => {
  const history = useHistory();
  const [post, setPost] = useState({});
  const { id } = useParams();
  useEffect(() => {
    // setPost(props.value);
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setPost(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("Data is : " + JSON.stringify(post));

  const updateVenue = () => {
    <UpdateVenue value={post} />;
  };

  return (
    <>
      <br />
      <br />
      <h4>
        <center>Venue Details</center>
      </h4>
      <br />
      <p> Title : {post.title} </p>
      <p> Country : {post.country} </p>
      <p> City : {post.city} </p>
      <p> Area : {post.area} </p>
      <p> Zip : {post.zip} </p>
      <br />
      <br />
      {/* <a href="#" onClick={updateVenue}>
        Update Venue
      </a> */}
      <Link to={"/update-venue/" + id}>Update Venue</Link>
    </>
  );
};

export default MoreDetails;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Checkbox from "../../venue-listing/check-box";
import { axios_instance as axios } from "../../../../boot/axios";
import { BarLoader } from "react-spinners";

const UpdateVenueCapacity = () => {
  const [data, setData] = useState({ seating: 0, standing: 0, minimum: 0 });
  const [styleItems, setStyleItems] = useState({}); //plain object as state
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setStyleItems({
      ...styleItems,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        console.log(JSON.stringify(resp.data.seating));
        setData({
          seating: resp.data.seating,
          standing: resp.data.standing,
          minimum: resp.data.minimum,
        });
        const res = resp.data.vstyle.reduce(
          (acc, curr) => ((acc[curr] = true), acc),
          {}
        );
        setStyleItems(res);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const checkboxes = [
    {
      name: "Standing",
      key: "checkBox1",
      label: "Standing",
    },
    {
      name: "Sofa Lounges",
      key: "checkBox2",
      label: "Sofa Lounges",
    },
    {
      name: "U-Shape",
      key: "checkBox3",
      label: "U-Shape",
    },
    {
      name: "Hollow Square",
      key: "checkBox4",
      label: "Hollow Square",
    },
    {
      name: "Board Room",
      key: "checkBox5",
      label: "Board Room",
    },
    {
      name: "Classroom",
      key: "checkBox6",
      label: "Classroom",
    },
    {
      name: "Theater",
      key: "checkBox7",
      label: "Theater",
    },
    {
      name: "Stadium",
      key: "checkBox8",
      label: "Stadium",
    },
    {
      name: "Caberet Style",
      key: "checkBox9",
      label: "Caberet Style",
    },
    {
      name: "Banquet",
      key: "checkBox10",
      label: "Banquet",
    },
  ];
  async function postData(e) {
    e.preventDefault();
    const state = { ...data, styleItems, id };
    axios
      .post("/venue/update-venue-capacity", state)
      .then((resp) => {
        resp.data.success
          ? alert("Venue Capacity Updated Successfully")
          : alert("Not Updated");
      })
      .catch((err) => console.log(err));
    console.log("State is : " + JSON.stringify(state));
  }

  return (
    <>
      {loading ? (
        <>
          <form onSubmit={postData}>
            <h6> Capacity </h6>
            <label for="seating"> Seating Capacity</label>
            <input
              id="seating"
              name="seating"
              value={data.seating}
              onChange={inputEvent}
              type="number"
            />
            <label for="standing"> Standing Capacity </label>
            <input
              id="standing"
              name="standing"
              value={data.standing}
              onChange={inputEvent}
              type="number"
            />
            <label for="minimum"> Minimum Capacity </label>
            <input
              id="minimum"
              name="minimum"
              value={data.minimum}
              onChange={inputEvent}
              type="number"
            />
            <br />
            <h6> Layout Style </h6>
            <br />
            {checkboxes.map((item) => (
              <label key={item.key}>
                {item.name}
                <Checkbox
                  name={item.name}
                  checked={styleItems[item.name]}
                  onChange={handleChange}
                />
                <br />
              </label>
            ))}
            <button type="submit"> Update </button> <hr />
          </form>
        </>
      ) : (
        <>
          <p> Loading.... </p>
          <BarLoader />
        </>
      )}
    </>
  );
};

export default UpdateVenueCapacity;

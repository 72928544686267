import React, { useState, useEffect } from "react";
import Checkbox from "../../venue-listing/check-box";
import { axios_instance as axios } from "../../../../boot/axios";
import { useHistory, useLocation, useParams } from "react-router";
import { BarLoader } from "react-spinners";

const UpdateFoodDrinks = () => {
  const { id } = useParams();
  const [foodItems, setFoodItems] = useState({});
  const [loading, setLoading] = useState(false);

  const Food = [
    {
      name: "External Catering Allowed",
      key: "checkBox1",
      value: "External Catering Allowed",
    },
    {
      name: "In house Catering",
      key: "checkBox2",
      value: "In house Catering",
    },
    {
      name: "Caterers on Panel",
      key: "checkBox3",
      value: "Caterers on Panel",
    },
    {
      name: "In house Soft Drinks and Mineral Water",
      key: "checkBox4",
      value: "In house Soft Drinks and Mineral Water",
    },
    {
      name: "Dedicated Pantry Area",
      key: "checkBox5",
      value: "Dedicated Pantry Area",
    },
    {
      name: "Dedicated Area for Live Cooking",
      key: "checkBox6",
      value: "Dedicated Area for Live Cooking",
    },
  ];

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        const res = resp.data.food.reduce(
          (acc, curr) => ((acc[curr] = true), acc),
          {}
        );
        console.log("BACKEND Data : " + JSON.stringify(res));
        setFoodItems(res);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const foodHandleChange = (event) => {
    setFoodItems({
      ...foodItems,
      [event.target.name]: event.target.checked,
    });
  };

  async function postData(e) {
    e.preventDefault();
    console.log("Food and Drinks : " + JSON.stringify(foodItems));
    axios
      .post("/venue/update-food-drinks", { foodItems, id })
      .then((resp) => {
        resp.data.success
          ? alert("Food Drinks Updated Successfully")
          : alert("Not Updated");
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      {loading ? (
        <>
          <form onSubmit={postData}>
            <h6> Food and Drinks </h6>
            {Food.map((item) => (
              <label key={item.key}>
                {item.name}
                <Checkbox
                  name={item.name}
                  checked={foodItems[item.name]}
                  onChange={foodHandleChange}
                />
                <br />
              </label>
            ))}
            <button type="submit"> Update</button>
          </form>
        </>
      ) : (
        <>
          <p> Loading.... </p>
          <BarLoader />
        </>
      )}
    </>
  );
};

export default UpdateFoodDrinks;

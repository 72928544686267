import { axios_instance as axios } from "../../../boot/axios";

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import { getSelected } from "../../agent-portal/venue-listing/helper";

const BookingDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [eventType, setEventType] = useState(null);
  const [id, setId] = useState(location.id);

  //   const [event, setEvent] = useState([]);
  //   const [channel, setChannel] = useState([]);

  const [budget, setBudget] = useState(null);
  const [guest, setGuest] = useState(null);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState("");

  const Event = [
    { label: "Annual Dinner", value: "Annual Dinner" },
    { label: "BTL Activation", value: "BTL Activation" },
    { label: "Business Meeting", value: "Business Meeting" },
    { label: "Conference/Seminar", value: "Conference/Seminar" },
    { label: "Networking Event", value: "Networking Event" },
    { label: "Training Workshop", value: "Training Workshop" },
    { label: "Product Launch", value: "Product Launch" },
    { label: "Exhibition", value: "Exhibition" },
    { label: "Media Event", value: "Media Event" },
    { label: "Bloggers Meet", value: "Bloggers Meet" },
    { label: "Day Out", value: "Day Out" },
    { label: "Engagement", value: "Engagement" },
    { label: "Nikkah Reception", value: "Nikkah Reception" },
    { label: "Mehndi", value: "Mehndi" },
    { label: "Barat", value: "Barat" },
    { label: "Valima Reception", value: "Valima Reception" },
    { label: "Birthday", value: "Birthday" },
    { label: "Special Celebration", value: "Special Celebration" },
    { label: "New Year", value: "New Year" },
    { label: "Graduation", value: "Graduation" },
    { label: "Anniversary", value: "Anniversary" },
    { label: "Weekend Brunch", value: "Weekend Brunch" },
    { label: "Bridal Shower", value: "Bridal Shower" },
    { label: "Private Dinning", value: "Private Dinning" },
    { label: "Iftar", value: "Iftar" },
    { label: "Concert", value: "Concert" },
    { label: "Award Show", value: "Award Show" },
    { label: "Music Gig", value: "Music Gig" },
    { label: "Fashion Show", value: "Fashion Show" },
    { label: "Festival", value: "Festival" },
  ];

  const handleEventSelect = (e) => {
    setEventType(e.value);
  };

  const handleBudget = (e) => {
    setBudget(e.target.value);
  };

  const handleGuest = (e) => {
    setGuest(e.target.value);
  };

  const onValueChange = (e) => {
    setStatus(e.target.value);
  };

  const postData = (e) => {
    e.preventDefault();
    const event = getSelected("event");
    const channel = getSelected("channel");
    console.log("Location Id : " + location.id);
    console.log("Hello Id : " + id);
    axios
      .post("/booking/booking-details", {
        eventType,
        budget,
        guest,
        event,
        status,
        channel,
        description,
        id,
      })
      .then((resp) => {
        if (resp.data.success) {
          history.push("/confirm-booking");
        }
      });
  };
  return (
    <>
      <br /> <br />
      <h3> Tell Us More About Your Event</h3>
      <br />
      <br />
      <form onSubmit={postData}>
        <h6> Select Event Type</h6>
        <Select
          options={Event}
          onChange={(e) => {
            setEventType([]);
            handleEventSelect(e);
          }}
        />
        <br />
        <h6> Your total budget</h6>
        <input
          type="number"
          placeholder="Type your total budget"
          onChange={handleBudget}
        />
        <br />
        <h6> Your Number of Guests</h6>
        <input
          type="number"
          placeholder="Type your number of guests"
          onChange={handleGuest}
        />
        <br />
        <br />
        <table id="event">
          Is this personal or business event?
          <input id="1" type="checkbox" value="Business" />
          <label for="1">Business</label>
          <input id="2" type="checkbox" value="Personal" />
          <label for="2">Personal</label>
        </table>
        <br />
        <h5>Is the timing of your event flexible?</h5>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="Yes"
              checked={status === "Yes"}
              onChange={onValueChange}
            />
            Yes, I have flexibility on my start time.
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              value="No"
              checked={status === "No"}
              onChange={onValueChange}
            />
            No, it must start on specified time.
          </label>
        </div>
        <br />
        <br />
        <table id="channel">
          Have you sent query to this venue through another channel?
          <input id="3" type="checkbox" value="Website" />
          <label for="3">VenueCity Website</label>
          <input id="4" type="checkbox" value="Phone" />
          <label for="4">Phone</label>
          <input id="5" type="checkbox" value="Email" />
          <label for="5">Email</label>
        </table>
        <br />
        <label for="description"> Description </label>
        <textarea
          name="description"
          id="address"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        <br /> <br />
        <button type="submit"> Save & Next </button>
      </form>
    </>
  );
};

export default BookingDetails;

import React, { useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  async function postData(e) {
    e.preventDefault();
    try {
      await axios
        .post("/api/auth/agent/change-password", {
          oldPassword,
          newPassword,
        })
        .then((resp) => {
          if (resp.data.success) {
            history.push("/agent-home");
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3>Set Password</h3>

          <Form onSubmit={postData}>
            <TextField
              label="Old Password"
              type="password"
              value={oldPassword}
              placeholder="Old Password"
              onChange={(e) => setOldPassword(e.target.value)}
              fullWidth
              required
            />{" "}
            <br />
            <br />
            <TextField
              label="New Password"
              type="password"
              value={newPassword}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              required
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Change Password
            </Button>
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { axios_instance as axios } from "../../../boot/axios";

const EditProfile = () => {
  const history = useHistory();
  const [eligible, setEligible] = useState(false);
  const [resp, setResp] = useState("");
  const [message, setMessage] = useState("");
  const [classStatus, setClassStatus] = useState("");

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  console.log("1");
  useEffect(async () => {
    let info;
    await axios.get("/api/auth/profile-data").then((resp) => {
      info = resp.data;
    });
    setData({ name: info.name, email: info.email, phone: info.phone });
  }, []);

  const inputCheckEvent = (event) => {
    inputEvent(event);
    // temp = event.target.value;
    const status = event.target.value.match(
      /(3[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])/
    );
    if (status && event.target.value.length == 10) {
      setEligible(true);
      setResp("Success!");
      setMessage("Accepted!");
      setClassStatus("alert alert-success");
      document.getElementById("getStatus").style.display = "block";
    } else {
      setResp("Error!");
      setMessage("Please enter a valid pattern 3123456789");
      setEligible(false);
      setClassStatus("alert alert-danger");
      document.getElementById("getStatus").style.display = "block";
    }
    // console.log("Temp length  : " + len);

    console.log("Matching status is : " + status);
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const postData = async (e) => {
    e.preventDefault();
    await axios.post("/api/auth/edit-profile", data).then((resp) => {
      console.log(JSON.stringify(resp.data));
      if (resp.data.success) {
        history.push("/agent-home");
      }
    });
  };
  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3> Edit Profile</h3>
          <Form onSubmit={postData}>
            <TextField
              label="Enter your name"
              type="text"
              name="name"
              value={data.name}
              onChange={inputEvent}
              fullWidth
              required
            />{" "}
            <br />
            <br />
            <TextField
              label="Enter email"
              type="email"
              name="email"
              value={data.email}
              onChange={inputEvent}
              fullWidth
              required
            />
            <br />
            <br />
            <div
              className={classStatus}
              id="getStatus"
              style={{ display: "none" }}
            >
              <strong>{resp}</strong> {message}{" "}
            </div>
            <TextField
              label="Enter phone"
              type="tel"
              name="phone"
              value={data.phone}
              onChange={inputCheckEvent}
              fullWidth
              required
            />
            <br />
            <br />
            <Button
              disabled={!eligible}
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Edit Profile
            </Button>
            <br />
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default EditProfile;

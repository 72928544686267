import { axios_instance as axios } from "../../../boot/axios";

export async function fetchCountries() {
  var arr = [];
  await axios
    .get("/venue/get-country")
    .then((res) => {
      arr = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return arr;
}

export async function fetchCities(country) {
  var arr = [];
  await axios
    .get("/venue/get-city/" + country)
    .then((res) => {
      arr = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
    console.log("Inside City");
  return arr;
}

export async function fetchAreas(city) {
  var arr = [];
  await axios
    .get("/venue/get-area/" + city)
    .then((res) => {
      arr = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
    console.log("Inside Area");
  return arr;
}

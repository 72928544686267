import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { axios_instance as axios } from "../../../../boot/axios";
import { BarLoader } from "react-spinners";
import Select from "react-select";

const UpdateSpaceDescription = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [initial, setInitial] = useState("");

  const [data, setData] = useState({
    description: "",
    slot: "",
    time: "",
    from: 0,
    to: 0,
    hours: 0,
    length: 0,
    width: 0,
    height: 0,
  });
  const [checked, setChecked] = useState();

  const Time = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
  ];

  const Slot = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setData({
          description: resp.data.description,
          slot: resp.data.slot,
          time: resp.data.time,
          from: resp.data.from,
          to: resp.data.to,
          length: resp.data.length,
          width: resp.data.width,
          height: resp.data.height,
          hours: resp.data.hours,
        });
        if (resp.data.hours == 0) {
          setChecked(false);
        } else {
          setChecked(true);
        }
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFromSelect = (e) => {
    setData({ ...data, from: e.value });
  };

  const handleToSelect = (e) => {
    setData({ ...data, to: e.value });
  };

  const handleSlotSelect = (e) => {
    setData({ ...data, slot: e.value });
  };

  const handleTimeSelect = (e) => {
    setData({ ...data, time: e.value });
  };

  function toggle(value) {
    if (!value) setData({ ...data, hours: 24 });
    else setData({ ...data, hours: 0 });
    return !value;
  }
  async function postData(e) {
    e.preventDefault();
    const state = { ...data, id };
    try {
      await axios
        .post("/venue/update-space-description", state)
        .then((resp) => {
          resp.data.success
            ? alert("Updated Space Dscription Successfully")
            : alert("Not Updated");
        });
    } catch (err) {
      console.log(err);
    }
    console.log("Space Description : " + JSON.stringify(state));
  }
  return (
    <>
      {loading ? (
        <>
          <form onSubmit={postData}>
            <h3> Space Description</h3>
            <label for="space"> Space About: </label>&nbsp;&nbsp;
            <textarea
              type="text"
              name="description"
              value={data.description}
              onChange={inputEvent}
              placeholder="Space Description"
              id="space"
            />
            <br /> <br /> <br /> <br />
            <h3> Operational Hours </h3> <br />
            <label for="from"> From: </label> &nbsp;&nbsp;
            <Select
              options={Time}
              value={{ label: data.from, value: data.from }}
              onChange={handleFromSelect}
            />
            <Select
              options={Slot}
              value={{ label: data.slot, value: data.slot }}
              onChange={handleSlotSelect}
            />
            <br /> <br />
            <label> To: </label> &nbsp;&nbsp;&nbsp; &nbsp;
            <Select
              options={Time}
              value={{ label: data.to, value: data.to }}
              onChange={handleToSelect}
            />
            <Select
              options={Slot}
              value={{ label: data.time, value: data.time }}
              onChange={handleTimeSelect}
            />
            <input
              id="hours"
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(toggle)}
            />
            <label for="hours"> Operating 24 hours</label>
            <br /> <br /> <br />
            <label for="length"> Length </label>
            <input
              id="length"
              type="number"
              name="length"
              value={data.length}
              onChange={inputEvent}
            />
            <label for="width"> Width </label>
            <input
              id="width"
              type="number"
              name="width"
              value={data.width}
              onChange={inputEvent}
            />
            <label for="height"> Height </label>
            <input
              id="height"
              type="number"
              name="height"
              value={data.height}
              onChange={inputEvent}
            />
            <button type="submit"> Update </button>
          </form>
        </>
      ) : (
        <>
          <p> Loading.... </p>
          <BarLoader />
        </>
      )}
    </>
  );
};

export default UpdateSpaceDescription;

import React, { useState } from "react";
import { useHistory } from "react-router";

const Queries = ({ data }) => {
  const history = useHistory();
  function Action(id) {
    history.push({
      pathname: "/booking-report",
      id,
    });
  }
  return (
    <>
      {/* {console.log(JSON.stringify(data))} */}
      <table>
        <tr>
          {/* <th>ID</th> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; */}
          <th>Name</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Contact</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Space Name</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Query Date</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Booking Status</th>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <th>Action</th>
        </tr>
        {data.map((data, key) => (
          <tr>
            {/* <td> 1</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; */}
            <td> {data.userId.name}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.userId.phone}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.eventType}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td> {data.created_at}</td>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            {data.booking_status ? <td> Approved</td> : <td> Wait </td>}
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <td>
              <button
                onClick={() => Action(data._id)}
                className="btn btn-success"
              >
                See report
              </button>
            </td>
          </tr>
        ))}
      </table>
    </>
  );
};

export default Queries;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import { BarLoader } from "react-spinners";

const Searchedvenue = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    axios
      .post("/venue/searched-venue", { location })
      .then((resp) => {
        setPost(resp.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  // console.log(location.city + ", " + location.etype + ", " + location.vtype);
  return (
    <>
      {loading ? <></> : <BarLoader />}
      <p>Hello Searched</p>
    </>
  );
};

export default Searchedvenue;

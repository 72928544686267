import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { axios_instance as axios } from "../../../../boot/axios";
import Checkbox from "../../venue-listing/check-box";
import { useParams } from "react-router";
import { BarLoader } from "react-spinners";

const UpdateFacilitySafety = () => {
  const [facilityItems, setFacilityItems] = useState({});
  const [safetyItems, setSafetyItems] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const Facilities = [
    { name: "Parking/Valet", key: "checkBox1", value: "Parking/Valet" },
    { name: "Disabled Access", key: "checkBox2", value: "Disabled Access" },
    { name: "Security", key: "checkBox3", value: "Security" },
    { name: "Air Conditioned", key: "checkBox4", value: "Air Conditioned" },
    { name: "Wi-Fi", key: "checkBox5", value: "Wi-Fi" },
    { name: "Bridal Room", key: "checkBox6", value: "Bridal Room" },
    { name: "Multimedia", key: "checkBox7", value: "Multimedia" },
    { name: "Restrooms", key: "checkBox8", value: "Restrooms" },
    { name: "Privacy", key: "checkBox9", value: "Privacy" },
    { name: "Segregation", key: "checkBox10", value: "Segregation" },
  ];

  const Safety = [
    { name: "Emergency Exit", key: "checkBox1", value: "Emergency Exit" },
    { name: "Fire Extinguisher", key: "checkBox2", value: "Fire Extinguisher" },
    { name: "Smoke Detector", key: "checkBox3", value: "Smoke Detector" },
    { name: "First Aid Kid", key: "checkBox4", value: "First Aid Kid" },
    { name: "Standby Ambulance", key: "checkBox5", value: "Standby Ambulance" },
  ];

  const objectFunction = (arr) => {
    return arr.reduce((acc, curr) => ((acc[curr] = true), acc), {});
  };

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        setFacilityItems(objectFunction(resp.data.facilities));
        setSafetyItems(objectFunction(resp.data.safety));
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const facilityHandleChange = (event) => {
    setFacilityItems({
      ...facilityItems,
      [event.target.name]: event.target.checked,
    });
  };

  const safetyHandleChange = (event) => {
    setSafetyItems({
      ...safetyItems,
      [event.target.name]: event.target.checked,
    });
  };

  async function postData(e) {
    e.preventDefault();
    // console.log("JSON Data : " + JSON.stringify(facilityItems));
    // console.log("JSON Data : " + JSON.stringify(safetyItems));
    axios
      .post("/venue/update-facility-saftey", {
        facilityItems,
        safetyItems,
        id,
      })
      .then((resp) => {
        resp.data.success
          ? alert("Facility Safety Updated Successfully")
          : alert("Not Updated");
      });
  }
  return (
    <>
      {loading ? (
        <>
          <h6> Facility and Safety </h6>
          <form onSubmit={postData}>
            <h6>Facilities</h6>
            {Facilities.map((item) => (
              <label key={item.key}>
                {item.name}
                <Checkbox
                  name={item.name}
                  checked={facilityItems[item.name]}
                  onChange={facilityHandleChange}
                />
                <br />
              </label>
            ))}

            <h6>Safety</h6>
            {Safety.map((item) => (
              <label key={item.key}>
                {item.name}
                <Checkbox
                  name={item.name}
                  checked={safetyItems[item.name]}
                  onChange={safetyHandleChange}
                />
                <br />
              </label>
            ))}
            <button type="submit"> Save and Finish </button>
          </form>
        </>
      ) : (
        <>
          <p> Loading.... </p>
          <BarLoader />
        </>
      )}
    </>
  );
};

export default UpdateFacilitySafety;

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

const SocialAccounts = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    snapchat: "",
  });

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  async function postData(e) {
    e.preventDefault();
    const fields = location.state;
    const state = { ...fields, ...data };
    console.log("Social Accounts : " + JSON.stringify(state));
    history.push({
      pathname: "/space-description",
      state,
    });
  }
  return (
    <>
      <form onSubmit={postData}>
        <label for="facebook"> Facebook </label>
        <input
          type="url"
          name="facebook"
          placeholder="Enter Facebook account URl here"
          value={data.facebook}
          onChange={inputEvent}
        />
        <label for="instagram"> Instagram </label>
        <input
          type="url"
          name="instagram"
          placeholder="Enter Instagram account URl here"
          value={data.instagram}
          onChange={inputEvent}
        />
        <label for="twitter"> Twitter </label>
        <input
          type="url"
          name="twitter"
          placeholder="Enter Twitter account URl here"
          value={data.twitter}
          onChange={inputEvent}
        />
        <label for="linkedin"> linkedIn </label>
        <input
          type="url"
          name="linkedin"
          placeholder="Enter Linkedin account URl here"
          value={data.linkedin}
          onChange={inputEvent}
        />
        <label for="snapchat"> Snapchat </label>
        <input
          type="url"
          name="snapchat"
          placeholder="Enter Snapchat account URl here"
          value={data.snapchat}
          onChange={inputEvent}
        />
        <button type="submit"> Save & Next</button>
      </form>
    </>
  );
};

export default SocialAccounts;

export function getSelected(idValue) {
  var selected = new Array();
  var nodeValue = document.getElementById(idValue);
  var chks = nodeValue.getElementsByTagName("INPUT");
  for (var i = 0; i < chks.length; i++) {
    if (chks[i].checked) {
      selected.push(chks[i].value);
    }
  }
  if (selected.length > 0) {
    return selected;
  }
}

import React, { useState } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import { useEffect } from "react";
import { BarLoader } from "react-spinners";
import Select from "react-select";
import Dropdown from "react-multilevel-dropdown";

const ManageUser = () => {
  const [owner, setOwner] = useState(null);
  const [invitee, setInvitee] = useState([]);
  const [accept, setAccept] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  // const [btn, setBtn] = useState(true);
  const [venue, setVenue] = useState([]);
  const [data, setData] = useState([]);
  const [brand, setBrand] = useState(true);
  const [Deactivate, setDeactivate] = useState(false);

  const Venues = [];

  async function getData() {
    await axios
      .get("/venue/venues")
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (!data[0]) {
    console.log("Data is : " + JSON.stringify(data));
    getData();
  }

  if (data) {
    data.forEach((venue) => {
      Venues.push({ label: venue.title, value: venue._id });
    });
  }

  async function userData() {
    try {
      await axios.get("/api/auth/profile-data").then((resp) => {
        setOwner(resp.data);
      });
      setLoading(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function inviteeData() {
    try {
      await axios.get("/api/auth/invitee-data").then((resp) => {
        setInvitee(resp.data);
      });
      // setLoading(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function acceptedInvitation() {
    // e.preventDefault();
    try {
      await axios.get("/api/auth/accepted-invitation").then((resp) => {
        setAccept(resp.data);
      });
      // setLoading(true);
    } catch (err) {
      console.log(err);
    }
  }

  // userData();
  // inviteeData();
  // acceptedInvitation();

  useEffect(() => {
    userData();
    inviteeData();
    acceptedInvitation();
  }, []);

  function handleVenuesSelect(e) {
    setVenue(e.map((e) => e.value));
  }

  const postData = (e) => {
    e.preventDefault();
    // console.log("Brand Level : " + brand);
    // console.log("Venue Level : " + venue);
    // console.log("Deactivate : " + Deactivate);

    // console.log("******");
    // console.log("Brand Level : " + brand);
    // console.log("Venue Level : " + venue);
    // console.log("Deactivate : " + Deactivate);
    axios
      .post("/api/auth/send-email", { email, brand, venue, Deactivate })
      .then((resp) => {
        if (resp.data.success) {
          console.log("Email has been sent");
          inviteeData();
        }
      });
  };

  function brandLevel(e, username) {
    e.preventDefault();
    venueDeactivation(username);
    axios
      .post("venue/brand-level-activation", { username })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("Brand Level Avtivation Function");
  }

  function venueLevel(e, username) {
    e.preventDefault();
    brandDeactivation(username);
    axios
      .post("venue/venue-level-activation", { venue, username })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function venueDeactivation(username) {
    setVenue([]);
    axios
      .post("venue/venue-level-activation", { venue, username })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function brandDeactivation(username) {
    axios
      .post("venue/brand-level-deactivation", { username })
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deactivate(e, username) {
    e.preventDefault();
    venueDeactivation(username);
    brandDeactivation(username);
  }

  return (
    <>
      {loading ? (
        <>
          <h5>
            <center> Manage Users Componenet </center>
          </h5>

          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Add Manager
          </button>

          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Invite Manager
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form
                  onSubmit={(e) => {
                    if (!brand && venue.length === 0 && !Deactivate) {
                      console.log("Inside");
                      setBrand(true);
                    }
                    postData(e);
                  }}
                >
                  <div class="modal-body">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter your email to invite manager"
                    />
                    <br />
                    <br />
                    <Dropdown
                      title="Access Level"
                      style={{
                        backgroundColor: "#5cb85c",
                        color: "white",
                      }}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setVenue([]);
                          setDeactivate(false);
                        }}
                      >
                        Brand Level
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Venue Level
                        <Dropdown.Submenu>
                          <Select
                            options={Venues}
                            isMulti
                            onChange={(e) => {
                              setVenue([]);
                              handleVenuesSelect(e);
                              setBrand(false);
                              setDeactivate(false);
                            }}
                          />
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setDeactivate(true);
                          setBrand(false);
                          setVenue([]);
                        }}
                      >
                        Deactivate
                      </Dropdown.Item>
                    </Dropdown>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" class="btn btn-primary">
                      Send Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-xs-12">
            <div class="row manage-users-grid-heading">
              <div class="col-md-9">
                <h4> Invited</h4>
              </div>
            </div>
            <div class="dashboard-list-box">
              <div class="dashboard-list-box-static">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>

                      <th scope="col">Created At</th>
                      <th scope="col">Access Level</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td> {owner.name} (You) </td>
                      <td> {owner.email} </td>
                      <td> Owner </td>
                      <td> Active </td>
                      <td> {owner.created_at}</td>
                      <td>
                        <button
                          disabled
                          type="button"
                          style={{ width: "100px" }}
                          className="btn btn-success"
                        >
                          Accessed
                        </button>
                      </td>
                    </tr>

                    {accept.map((element, key) => (
                      <tr>
                        <td> {element.name} </td>
                        <td> {element.email} </td>
                        <td> Manager </td>
                        <td> Active </td>
                        <td> {element.created_at} </td>
                        {/* <td>
                          {btn ? (
                            <button
                              type="button"
                              onClick={(e) => brandActivation(e, element.email)}
                              className="btn btn-success"
                              style={{ width: "100px" }}
                            >
                              Activate
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) =>
                                brandDeactivation(e, element.email)
                              }
                              className="btn btn-danger"
                              style={{ width: "100px" }}
                            >
                              Deactivate
                            </button>
                          )}
                        </td> */}
                        <td>
                          <Dropdown
                            title="Access Level"
                            style={{
                              backgroundColor: "#5cb85c",
                              color: "white",
                            }}
                          >
                            <Dropdown.Item
                              onClick={(e) => brandLevel(e, element.email)}
                            >
                              Brand Level
                            </Dropdown.Item>
                            <Dropdown.Item>
                              Venue Level
                              <Dropdown.Submenu>
                                <form
                                  onSubmit={(e) => venueLevel(e, element.email)}
                                >
                                  <Select
                                    options={Venues}
                                    isMulti
                                    onChange={handleVenuesSelect}
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                </form>
                              </Dropdown.Submenu>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => deactivate(e, element.email)}
                            >
                              Deactivate
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <h4> Pending Invitations</h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Invited By</th>
                    <th scope="col">Brand Name</th>
                    <th scope="col">Status</th>

                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                {invitee.map((element, key) => (
                  <tr>
                    <td> {element.email}</td>
                    <td> Manager </td>
                    <td> {element.invited_by} </td>
                    <td> {element.brand_name} </td>
                    <td> {element.status} </td>
                    <td> {element.created_at} </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </>
      ) : (
        <BarLoader loading />
      )}
    </>
  );
};

export default ManageUser;

import React, { useState, useRef } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./signup.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory, useParams } from "react-router";

const HostSignup = () => {
  const { email } = useParams();
  const [eligible, setEligible] = useState(false);
  const [resp, setResp] = useState("");
  const [message, setMessage] = useState("");
  const [classStatus, setClassStatus] = useState("");

  const inputCheckEvent = (event) => {
    inputEvent(event);
    const status = event.target.value.match(
      /(3[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])/
    );
    if (status && event.target.value.length == 10) {
      setEligible(true);
      setResp("Success!");
      setMessage("Accepted!");
      setClassStatus("alert alert-success");
      setStyeleStatus("block");
    } else {
      setResp("Error!");
      setMessage("Please enter a valid pattern 3123456789");
      setEligible(false);
      setClassStatus("alert alert-danger");
      setStyeleStatus("block");
    }
  };

  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    email: email,
    phone: "",
    password: "",
    cpassword: "",
  });

  const [styleStatus, setStyeleStatus] = useState("none");

  const inputEvent = (event) => {
    console.log(event.target.value);
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  async function postData(e) {
    e.preventDefault();
    const name = data.name;
    const email = data.email;
    const password = data.password;
    const cpassword = data.cpassword;
    const phone = data.phone;
    if (password === cpassword) {
      try {
        await axios.post("/api/auth/check-email", data.email).then((resp) => {
          if (resp.data.success) {
            axios.post("/api/auth/set-number", data.phone);
            axios.post("/api/auth/send-code");
            history.push({
              pathname: "/code-verification",
              data,
            });
          } else {
            console.log("Email already exist.");
          }
        });
        console.log("Hello: 2");
        // history.push({
        //   pathname: "/agent-home",
        // });
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("both password not matched");
    }
  }

  return (
    <Grid className="gridStyle">
      <Paper elevation={10} className="paperStyle">
        <h3> Sign Up</h3>
        <Form onSubmit={postData}>
          <TextField
            label="Enter your name"
            type="text"
            placeholder="Enter your name"
            name="name"
            value={data.name}
            onChange={inputEvent}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Enter email"
            type="email"
            placeholder="Enter email"
            name="email"
            value={data.email}
            onChange={inputEvent}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Enter password"
            type="password"
            placeholder="Enter password"
            name="password"
            value={data.password}
            onChange={inputEvent}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Confirm password"
            type="password"
            placeholder="Confirm password"
            name="cpassword"
            value={data.cpassword}
            onChange={inputEvent}
            fullWidth
            required
          />
          <br />
          <br />
          {/* <div
            className="alert alert-success"
            id="success"
            style={{ display: "none" }}
          >
            <strong>{resp}</strong> {message}
          </div> */}
          <div
            className={classStatus}
            id="getStatus"
            style={{ display: styleStatus }}
          >
            <strong>{resp}</strong> {message}
          </div>
          <TextField
            label="Enter phone"
            type="tel"
            placeholder="Enter phone"
            name="phone"
            value={data.phone}
            onChange={inputCheckEvent}
            fullWidth
            required
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox name="terms" color="primary" />}
            label="I accept all terms and conditions."
          />
          <Button
            disabled={!eligible}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: "8px 0" }}
            fullWidth
          >
            Signup
          </Button>
          <br />
          <Typography>
            Do you have an account ? <Link to="/login"> Login</Link>
          </Typography>
        </Form>
      </Paper>
    </Grid>
  );
};

export default HostSignup;

import { axios_instance as axios } from "../../../boot/axios";

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import DatePicker from "sassy-datepicker";
import TimePicker from "react-time-picker";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const VenueProfile = () => {
  const [post, setPost] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [booked, setBooked] = useState([]);
  const [free, setFree] = useState([]);
  const [review, setReview] = useState("");
  const [user, setUser] = useState("");
  const [comments, setComments] = useState([]);
  const [state, setState] = useState(false);
  const [flag, setFlag] = useState(false);
  // pt-PT

  useEffect(() => {
    axios
      .get("/venue/get-venue/" + id)
      .then((resp) => {
        setPost(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/api/auth/current-user")
      .then((resp) => {
        setUser(resp.data);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log("Booked : " + JSON.stringify(booked));
  useEffect(() => {
    console.log("Booked schedule : " + date);
    axios
      .post("/venue/booked-slots", { date, id })
      .then((resp) => {
        setBooked(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  useEffect(() => {
    setFlag(!flag);
    axios
      .post("/venue/available-slots", { startTime, endTime, date, id })
      .then((resp) => {
        setFree(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [date]);

  useEffect(() => {
    axios
      .post("/booking/booking-time", { startTime, endTime, date, id })
      .then((resp) => {
        console.log("Flag is : " + resp.data.success);
        setFlag(resp.data.success);
      });
  }, [startTime, endTime]);

  useEffect(() => {
    axios
      .get("/venue/reviews/" + id)
      .then((resp) => {
        setComments(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  const postData = (e) => {
    e.preventDefault();
    if (flag) {
      axios
        .post("/booking/venue-booking", { id, date, startTime, endTime })
        .then((resp) => {
          console.log("resp : " + JSON.stringify(resp));
          if (resp.data.success) {
            const bookingId = resp.data.id;
            history.push({
              pathname: "/venue-booking",
              bookingId,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeDate = (e) => {
    setDate(e.toLocaleDateString());
  };

  const reviewChangeHandler = (e) => {
    setReview(e.target.value);
  };
  const postReview = (e) => {
    e.preventDefault();
    axios.post("/venue/venue-review", { review, id }).then((resp) => {
      if (resp.data.success) {
        setState(!state);
        setReview("");
        alert("Review Added Successfully");
      } else alert("Review Not Added");
    });
  };

  const editReview = (id) => {
    axios.post("/venue/edit-review", { review, id }).then((resp) => {
      if (resp.data.success) {
        setReview("");
        setState(!state);
        alert("Review Editted Successfully!");
      } else alert("Review not updated");
    });
  };

  const deleteReview = (id) => {
    axios.post("/venue/delete-review", { id }).then((resp) => {
      if (resp.data.success) {
        alert("Review Deleted Successfully!");
        setState(!state);
        setReview("");
      } else {
        alert("Review not Deleted");
      }
    });
  };

  const ratingChanged = (rating) => {
    // console.log(rating);
    axios.post("/venue/venue-rating", { rating, id }).then((resp) => {
      resp.data.success
        ? console.log("Rating added")
        : console.log("Rating not added");
    });
  };

  return (
    <>
      <br />
      <br />
      <div class="col-xs-12 col-md-6 col-lg-4">
        <div class="card mb-4">
          <a class="img-card" href="#">
            <small class="white front tiny">
              {post.from} - {post.to}
              <br />
              Timing
            </small>
            {/* <div class="review-card">
              <i class="fas fa-star"></i> <i class="fas fa-star"></i>
              <i class="fas fa-star"></i> <i class="fas fa-star"></i>
              <i class="fas fa-star-half"></i>
              <span class="tiny white">2 reviews</span>
            </div> */}
            <div class="bottom-tour-background"></div>
            <img src="assets/images/contact.jpg" alt="image" />
          </a>
          <div class="card-content">
            <div class="row align-items-center">
              <div class="col-8">
                <h6 class="black mb-2">
                  <a href="#" target="_blank">
                    {post.title}
                  </a>
                </h6>
              </div>
              <div class="col-4 align-middle">
                <h6 class="primary-color text-right">{post.price}</h6>
              </div>
              <div>
                <p>
                  &nbsp;&nbsp;
                  <i class="far fa-building"></i>&nbsp;&nbsp;{post.vtype}
                </p>
                <p>
                  &nbsp;&nbsp;
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  &nbsp;&nbsp;
                  {post.country + ", " + post.city + ", " + post.area}
                </p>
                <p>
                  &nbsp;&nbsp;
                  <i class="fas fa-male"></i>&nbsp;&nbsp;
                  {"Seating " + post.seating + " / Standing " + post.standing}
                </p>

                {/* <a
                  class="btn btn-primary px-3 ml-3 mr-1 my-1 btn-sm float-left"
                  href="#"
                  role="button"
                  style={{ width: "auto" }}
                >
                  View Profile
                </a> */}
                {/* <Link
                  class="btn btn-primary px-3 btn-sm float-left" style={{width: "auto"}}
                  to={"/venue-profile/" + data._id}
                >
                  View Profile
                </Link> */}
                {/* <a
                class="btn btn-primary mx-1 px-3 mx-2 my-1 btn-sm float-left"
                href="#"
                role="button"
              >
                travel
              </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <ReactStars
        count={5}
        onChange={ratingChanged}
        size={24}
        activeColor="#ffd700"
      />
      ,
      <br />
      <br />
      <h6>Comments : </h6>
      {comments.map((data, key) => (
        <>
          <img
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y"
            alt="Avatar"
            style={{
              verticalAlign: "middle",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
            }}
          />
          <h8>{data.userId.name}</h8>
          <p>{data.review}</p>
          {/* {console.log("Data : " + data.userId._id + " | " + user._id)} */}
          {data.userId._id === user._id ? (
            <>
              <button onClick={() => setReview(data.review)}>Edit </button>
              &nbsp;&nbsp;&nbsp;
              <button onClick={() => editReview(data._id)}>Update</button>
              &nbsp;&nbsp;&nbsp;
              <button onClick={() => deleteReview(data._id)}>Delete</button>
              <br />
              <br />
            </>
          ) : (
            ""
          )}
          {/* <a href="#" onClick={editReview(data._id, data.review)}>
            Edit
          </a> */}
        </>
      ))}
      <br />
      <br />
      <form onSubmit={postReview}>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group text-center">
              <h5 for="form_message">Review here</h5>
              <textarea
                id="form_message"
                name="review"
                value={review}
                onChange={reviewChangeHandler}
                class="form-control"
                placeholder="What is your review? *"
                rows="4"
                required="required"
                data-error="Please,leave a comment"
              ></textarea>
              <div class="help-block with-errors tiny mt-2"></div>
            </div>
          </div>
          <div class="col-md-12">
            <input
              type="submit"
              class="btn btn-primary px-4 btn-send"
              value="Add Review"
            />
          </div>
        </div>
      </form>
      <br />
      <br />
      <form onSubmit={postData}>
        <DatePicker onChange={onChangeDate} value={date} />
        <TimePicker onChange={setStartTime} value={startTime} />
        <TimePicker onChange={setEndTime} value={endTime} />
        <button type="submit" class="btn btn-primary px-3 btn-sm float-left">
          Request to Book
        </button>
      </form>
      <br /> <br />
      <br />
      <br />
      <h4>Free slots on this date </h4>
      {free.map((data, key) => (
        <>
          <p>Date : {date}</p>
          <p>
            From : {data.from} ----- {data.to}
          </p>
          <hr />
        </>
      ))}
      <br /> <br />
      <br />
      <br />
      <h4>Booked Slots on this date </h4>
      {booked.map((data, key) => (
        <>
          <p>Date : {date}</p>
          <p>
            From : {data.from} ----- {data.to}
          </p>
          <hr />
        </>
      ))}
    </>
  );
};

export default VenueProfile;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import UpdateInitialList from "./update-initial-list";

const UpdateVenue = () => {
  const { id } = useParams();
  // console.log("Id is : " + props.match.params.id);
  // const initialList = () => {
  //   <UpdateInitialList value={props.value} />;
  // };
  return (
    <>
      <h2>
        <center> Update your Venue </center>
        <li>
          {/* <a href="#" onClick={initialList}>
            Initial List
          </a> */}
          <Link to={"/update-initial-list/" + id}>Initial List</Link>
          <br />
        </li>
        {/* map direction  */}
        <li>
          <Link to={"/update-social-accounts/" + id}> Social Accounts</Link>
          <br />
        </li>
        <li>
          <Link to={"/update-space-description/" + id}> Space Description</Link>
          <br />
        </li>
        <li>
          <Link to={"/update-venue-capacity/" + id}> Venue Capacity</Link>
          <br />
        </li>
        <li>
          <Link to={"/update-venue-details/" + id}> Venue Details</Link>
          <br />
        </li>
        <li>
          <Link to={"/update-food-drinks/" + id}> Food & Drinks</Link>
          <br />
        </li>
        <li>
          <Link to={"/update-facility-safety/" + id}> Facility Safety</Link>
          <br />
        </li>
      </h2>
    </>
  );
};

export default UpdateVenue;

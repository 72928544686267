import React from "react";
import { Body } from "../home/body/body";
import { Footer } from "./footer/footer";
import { Header } from "./header/header";

const Home = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
};

export default Home;

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";

const SpaceDescription = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({
    description: "",
    slot: "AM",
    time: "AM",
    from: 1,
    to: 1,
    hours: 0,
    length: 0,
    width: 0,
    height: 0,
  });
  const [checked, setChecked] = useState(false);

  const Time = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
  ];

  const Slot = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    // console.log(data.description + " " + data.from + " "+ data.to + " " + data.hours + " " + data.length+ " " + data.width+ " " + data.height);
  };
  function toggle(value) {
    if (!value) setData({ ...data, hours: 24 });
    else setData({ ...data, hours: 0 });
    return !value;
  }

  const handleFromSelect = (e) => {
    setData({ ...data, from: e.value });
  };

  const handleToSelect = (e) => {
    setData({ ...data, to: e.value });
  };

  const handleSlotSelect = (e) => {
    setData({ ...data, slot: e.value });
  };

  const handleTimeSelect = (e) => {
    setData({ ...data, time: e.value });
  };

  async function postData(e) {
    e.preventDefault();
    const fields = location.state;
    const state = { ...fields, ...data };
    // console.log("Space Description : " + JSON.stringify(state));
    history.push({
      pathname: "/venue-capacity",
      state,
    });
  }
  return (
    <>
      <form onSubmit={postData}>
        <h6> Space Description</h6>
        <label for="space"> Space About: </label>&nbsp;&nbsp;
        <textarea
          type="text"
          name="description"
          value={data.description}
          onChange={inputEvent}
          placeholder="Space Description"
          id="space"
        />
        <br /> <br /> <hr />
        <h6> Operational Hours </h6> <br />
        <label for="from"> From: </label> &nbsp;&nbsp;
        <Select
          options={Time}
          value={{ label: data.from, value: data.from }}
          onChange={handleFromSelect}
        />
        <Select
          options={Slot}
          value={{ label: data.slot, value: data.slot }}
          onChange={handleSlotSelect}
        />
        <br /> <br />
        <label> To: </label> &nbsp;&nbsp;&nbsp; &nbsp;
        <Select
          options={Time}
          value={{ label: data.to, value: data.to }}
          onChange={handleToSelect}
        />
        <Select
          options={Slot}
          value={{ label: data.time, value: data.time }}
          onChange={handleTimeSelect}
        />
        <input
          id="hours"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(toggle)}
        />
        <label for="hours"> Operating 24 hours</label>
        <br />
        <label for="length"> Length </label>
        <input
          id="length"
          type="number"
          name="length"
          value={data.length}
          onChange={inputEvent}
        />
        <label for="width"> Width </label>
        <input
          id="width"
          type="number"
          name="width"
          value={data.width}
          onChange={inputEvent}
        />
        <label for="height"> Height </label>
        <input
          id="height"
          type="number"
          name="height"
          value={data.height}
          onChange={inputEvent}
        />
        <button type="submit"> Next </button>
      </form>
    </>
  );
};

export default SpaceDescription;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  fetchCities,
  fetchCountries,
  fetchAreas,
} from "../../user-portal/explore-venue/location";
import Select from "react-select";
import { axios_instance as axios } from "../../../boot/axios";

const InitialListing = () => {
  const history = useHistory();
  const [image, setImage] = useState(null);
  const [data, setData] = useState({
    title: "",
    phone: "",
    zip: "",
    address: "",
    website: "",
    country: "",
    city: "",
    area: "",
    price: 0,
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  const [classState, setClassState] = useState({
    eligible: false,
    resp: "",
    message: "",
    classStatus: "",
    styleStatus: "none",
  });

  const inputCheckEvent = (event) => {
    const status = event.target.value.match(
      /(03[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])/
    );
    if (status && event.target.value.length == 11) {
      setClassState({
        eligible: true,
        resp: "Success!",
        message: "Accepted!",
        classStatus: "alert alert-success",
        styleStatus: "block",
      });
    } else {
      setClassState({
        eligible: false,
        resp: "Error!",
        message: "Please enter a valid pattern 03123456789",
        classStatus: "alert alert-danger",
        styleStatus: "block",
      });
    }
  };

  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setImage(event.target.files[0]);
  };

  useEffect(() => {
    let temp = [];
    fetchCountries().then((countries) => {
      countries.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setCountries(temp);
    });
  }, []);

  useEffect(() => {
    fetchCities(country).then((cities) => {
      let temp = [];
      setAreas("");
      cities.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setCities(temp);
    });
  }, [country]);

  useEffect(() => {
    let temp = [];
    fetchAreas(city).then((areas) => {
      areas.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setAreas(temp);
    });
  }, [city]);

  const reset = () => {
    setArea("");
    setCity("");
  };

  const handleCountrySelect = (e) => {
    setCountry(e.value);
  };

  const handleCitySelect = (e) => {
    setCity(e.value);
  };

  const handleAreaSelect = (e) => {
    setArea(e.value);
  };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    // console.log(data.title + " " + data.phone + " "+ data.country + " " + data.zip + " " + data.city + " " + data.area + " " + data.address + " " + data.website);
  };

  async function postData(e) {
    e.preventDefault();

    console.log("Initils Listing : " + JSON.stringify(data));
    let formData = new FormData();
    formData.append("file", image);
    try {
      await axios
        .post("/venue/save-file", formData, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    const path = image.name;

    const initialState = { ...data, path, country, city, area };
    console.log("Image data : " + image.name);
    history.push({
      pathname: "/map-direction",
      initialState,
    });
  }

  return (
    <>
      <form onSubmit={postData}>
        <label for="title">Title</label>
        <input
          id="title"
          type="text"
          className="title"
          placeholder="Title"
          value={data.title}
          onChange={inputEvent}
          name="title"
        />
        <label for="price">Price</label>
        <input
          id="price"
          type="number"
          placeholder="Price"
          value={data.price}
          onChange={inputEvent}
          name="price"
        />
        <div
          className={classState.classStatus}
          // id="getStatus"
          style={{ display: classState.styleStatus }}
        >
          <strong>{classState.resp}</strong> {classState.message}
        </div>
        <label for="phone">Phone</label>
        <input
          label="Enter phone"
          type="tel"
          placeholder="Enter phone"
          name="phone"
          value={data.phone}
          onChange={(e) => {
            inputCheckEvent(e);
            inputEvent(e);
          }}
          placeholder="Mobile Number"
          required
        ></input>
        <label for="zip">Postal Code</label>
        <input
          id="zip"
          placeholder="Postal Code"
          name="zip"
          value={data.zip}
          onChange={inputEvent}
          type="number"
        ></input>
        <br /> <br />
        <Select
          options={countries}
          onChange={(e) => {
            handleCountrySelect(e);
            reset();
          }}
        />
        <br />
        <Select
          options={cities}
          value={city == "" ? "" : { label: city, value: city }}
          onChange={(e) => {
            handleCitySelect(e);
            setArea("");
          }}
        />
        <br />
        <Select
          options={areas}
          value={area == [] ? [] : { label: area, value: area }}
          onChange={handleAreaSelect}
        />
        <br />
        <br /> <br />
        <label for="address"> Address </label>
        <textarea
          name="address"
          id="address"
          value={data.address}
          onChange={inputEvent}
        ></textarea>
        <br /> <br />
        <input type="file" name="file" onChange={onChangeHandler} />
        <label for="website">Website</label>
        <input
          type="url"
          placeholder="Website Url"
          id="website"
          name="website"
          value={data.website}
          onChange={inputEvent}
        ></input>
        <button disabled={!classState.eligible} type="submit">
          Save & Next
        </button>
      </form>
    </>
  );
};

export default InitialListing;

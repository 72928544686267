import { axios_instance as axios } from "../../../boot/axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Queries from "./queries";
import DatePicker from "sassy-datepicker";
import Select from "react-select";

const BoookingQueries = () => {
  const [booking, setBooking] = useState([]);
  const [data, setData] = useState({
    space: "",
    filter: "",
    queryStatus: "ALL",
  });
  // const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [date, setDate] = useState(new Date());

  const queryStatus = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "In Process", value: "In Process" },
    { label: "Answered", value: "Answered" },
    { label: "Booked", value: "Booked" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Duplicate", value: "Duplicate" },
  ];

  useEffect(async () => {
    try {
      await axios.get("/booking/get-booked-venue").then((resp) => {
        console.log("All the booking queries are : " + JSON.stringify(resp));
        setBooking(resp.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const statusHandler = (e) => {
    setData({ ...data, queryStatus: e.value });
  };

  console.log(date);
  const postData = (e) => {
    e.preventDefault();
    const schedule = date.toISOString().slice(0, 10);
    const post = { ...data, schedule };
    console.log(post);
    axios
      .post("/booking/filtered-queries", post)
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(JSON.stringify(data) + date.toISOString().slice(0, 10));
  };

  return (
    <>
      <br />
      <h6> Refind Search </h6>
      <br />
      <form onSubmit={postData}>
        Query Date :
        <DatePicker onChange={setDate} value={date} />
        <br />
        Space Name :
        <input
          type="text"
          name="space"
          value={data.space}
          onChange={inputEvent}
        />
        <br />
        Filter By :
        <input
          type="text"
          name="filter"
          value={data.filter}
          onChange={inputEvent}
        />
        <br />
        Status:
        <Select
          options={queryStatus}
          value={{ label: data.queryStatus, value: data.queryStatus }}
          onChange={statusHandler}
        />
        <br />
        <button type="submit"> Filter </button>
      </form>
      <hr />
      <br />
      <br />
      <h5> My Quesries are</h5> <br />
      <Queries data={booking} />
    </>
  );
};

export default BoookingQueries;

import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory, useLocation } from "react-router";

const Verification = () => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const location = useLocation();

  // const resendCode = async () => {
  //   // console.log("Phone no : " + phone + name);
  //   try {
  //     await axios.post("/api/auth/send-code")
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  async function postData(e) {
    e.preventDefault();
    try {
      await axios.post("/api/auth/verify-code", { code }).then((response) => {
        if (response.data.success) {
          const data = location.data;
          console.log(JSON.stringify(data));
          try {
            axios.post("/api/auth/agent/signup", data).then((response) => {
              console.log("Inside Response");
              if (response.data.success) {
                history.push("/login");
              }
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3> Verify Code</h3>
          <Form onSubmit={postData}>
            <TextField
              label="Enter your code"
              type="number"
              placeholder="Enter verification code"
              name="code"
              value={code}
              onChange={(event) => setCode(event.target.value)}
              fullWidth
              required
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Verify
            </Button>
            <br />
            {/* Did not receive code?
            <a href="#" onClick={resendCode}>
              Resend code
            </a> */}
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default Verification;

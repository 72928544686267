import React from "react";

const Help = () => {
  return (
    <>
      <h1> Help Page </h1>
    </>
  );
};

export default Help;

import "./App.css";
// import Login_a from "./componenets/user-portal/user-login/user-login-a";
// import Login_b from "./componenets/user-portal/user-login/user-login-b";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import UserSignup from "./componenets/user-portal/user-signup/user-signup";
import Login from "./componenets/agent-portal/login/login";
import Signup from "./componenets/agent-portal/signup/signup";
import Home from "./componenets/user-portal/home/home";
import InitialListing from "./componenets/agent-portal/venue-listing/initial-listing";
import MapDirection from "./componenets/agent-portal/venue-listing/map-direction";
import SocialAccounts from "./componenets/agent-portal/venue-listing/social-accounts";
import SpaceDescription from "./componenets/agent-portal/venue-listing/space-description";
import VenueCapacity from "./componenets/agent-portal/venue-listing/venue-capacity";
import VenueDetails from "./componenets/agent-portal/venue-listing/venue-details";
import Food from "./componenets/agent-portal/venue-listing/food-drinks";
import Facility from "./componenets/agent-portal/venue-listing/facility-safety";
import Help from "./componenets/user-portal/information/help";
import Contact from "./componenets/user-portal/information/contact";
import About from "./componenets/user-portal/information/about";
import OurTeam from "./componenets/user-portal/information/our-team";
// import Explore from "./componenets/user-portal/explore-venue/explore-venue";
import SetPassword from "./componenets/user-portal/user-login/set-password";
import VenueBrand from "./componenets/agent-portal/venue-brand/venue-brand";
import AgentHome from "./componenets/agent-portal/home/home";
import Verification from "./componenets/agent-portal/signup/verification";
import Filters from "./componenets/user-portal/explore-venue/venue-filters";
import ForgotPassword from "./componenets/agent-portal/forgot-password/forgot-password";
import SetForgotPassword from "./componenets/agent-portal/forgot-password/set-password";
import ChangePassword from "./componenets/agent-portal/forgot-password/change-password";
import BrandDetails from "./componenets/agent-portal/venue-brand/brand-details";
import UpdateBrand from "./componenets/agent-portal/venue-brand/update-brand";
import ShowVenues from "./componenets/agent-portal/venues/show-venues";
import MoreDetails from "./componenets/agent-portal/venues/more-details";
import UpdateVenues from "./componenets/agent-portal/venues/update-venues";
import CodeVerification from "./componenets/agent-portal/forgot-password/password-verification";
import EditProfile from "./componenets/agent-portal/profile/edit-profile";
import UpdateInitialList from "./componenets/agent-portal/venues/update-venue/update-initial-list";
// import UpdateMapDirection from "./componenets/agent-portal/venues/update-venue/update-map-direction";
import UpdateSocialAccounts from "./componenets/agent-portal/venues/update-venue/update-social-accounts";
import UpdateVenue from "./componenets/agent-portal/venues/update-venue/update-venue";

import UpdateSpaceDescription from "./componenets/agent-portal/venues/update-venue/update-space-description";
import UpdateVenueCapacity from "./componenets/agent-portal/venues/update-venue/update-venue-capacity";
import UpdateVenueDetails from "./componenets/agent-portal/venues/update-venue/update-venue-details";
import UpdateFoodDrinks from "./componenets/agent-portal/venues/update-venue/update-food-drinks";
import UpdateFacilitySafety from "./componenets/agent-portal/venues/update-venue/update-facility-safety";
import VenueProfile from "./componenets/user-portal/venue-booking/venue-profile";
import VenueBooking from "./componenets/user-portal/venue-booking/venue-booking";
import BookingDetails from "./componenets/user-portal/venue-booking/booking-details";
import ConfirmBooking from "./componenets/user-portal/venue-booking/confirm-booking";
import BoookingQueries from "./componenets/user-portal/venue-booking/booking-queries";
import BookingReport from "./componenets/user-portal/venue-booking/booking-report";
import ManageUser from "./componenets/agent-portal/manage-users/manage-user";
import HostSignup from "./componenets/agent-portal/signup/host-signup";
import Searchedvenue from "./componenets/user-portal/explore-venue/searched-venue";
import IncomingRequest from "./componenets/agent-portal/booking/incoming-request";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Switch>
          <Route exact path="/" exact component={Home} />
          <Route exact path="/home" exact component={Home} />
          {/* <Route exact path="/login" component={Login_a} /> */}
          {/* <Route exact path="/user-login" component={Login_b} /> */}
          {/* <Route exact path="/user-signup" component={UserSignup} /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/initial-listing" component={InitialListing} />
          <Route exact path="/map-direction" component={MapDirection} />
          <Route exact path="/social-accounts" component={SocialAccounts} />
          <Route exact path="/space-description" component={SpaceDescription} />
          <Route exact path="/venue-capacity" component={VenueCapacity} />
          <Route exact path="/venue-details" component={VenueDetails} />
          <Route exact path="/food-drinks" component={Food} />
          <Route exact path="/facility-safety" component={Facility} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about" component={About} />
          <Route exact path="/set-password" component={SetPassword} />
          <Route exact path="/venue-brand" component={VenueBrand} />
          <Route exact path="/agent-home" component={AgentHome} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/code-verification" component={Verification} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/brand-details" component={BrandDetails} />
          <Route exact path="/update-brand" component={UpdateBrand} />
          <Route exact path="/show-venues" component={ShowVenues} />
          <Route exact path="/more-details/:id" component={MoreDetails} />
          <Route exact path="/update-venue/:id" component={UpdateVenue} />
          <Route exact path="/edit-profile" component={EditProfile} />
          <Route
            exact
            path="/update-initial-list/:id"
            component={UpdateInitialList}
          />
          {/* <Route
            exact
            path="/update-map-direction"
            component={UpdateMapDirection}
          /> */}
          <Route
            exact
            path="/update-social-accounts/:id"
            component={UpdateSocialAccounts}
          />
          <Route
            exact
            path="/update-space-description/:id"
            component={UpdateSpaceDescription}
          />
          <Route
            exact
            path="/update-venue-capacity/:id"
            component={UpdateVenueCapacity}
          />
          <Route
            exact
            path="/update-venue-details/:id"
            component={UpdateVenueDetails}
          />
          <Route
            exact
            path="/update-food-drinks/:id"
            component={UpdateFoodDrinks}
          />
          <Route
            exact
            path="/update-facility-safety/:id"
            component={UpdateFacilitySafety}
          />
          <Route
            exact
            path="/forgot-code-verification"
            component={CodeVerification}
          />
          <Route
            exact
            path="/set-forget-password"
            component={SetForgotPassword}
          />
          <Route exact path="/explore-venues" component={Filters} />
          <Route exact path="/venue-profile/:id" component={VenueProfile} />
          <Route exact path="/venue-booking" component={VenueBooking} />
          <Route exact path="/booking-details" component={BookingDetails} />
          <Route exact path="/confirm-booking" component={ConfirmBooking} />
          <Route exact path="/booking-queries" component={BoookingQueries} />
          <Route exact path="/booking-report" component={BookingReport} />
          <Route exact path="/manage-users" component={ManageUser} />
          <Route exact path="/host-signup/:email" component={HostSignup} />
          <Route exact path="/searched-venue" component={Searchedvenue} />
          <Route exact path="/our-team" component={OurTeam} />
          <Route exact path="/incoming-request" component={IncomingRequest} />
          <Route exact path="/update-venues" component={UpdateVenues} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

const SetForgotPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [cpassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState(location.email);

  async function postData(e) {
    e.preventDefault();
    if (password == cpassword) {
      try {
        await axios
          .post("/api/auth/agent/set-password", {
            email,
            password,
          })
          .then((resp) => {
            if (resp.data.success) {
              history.push("/agent-login");
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      //javascript code
    }
  }
  return (
    <>
      <Grid className="gridStyle">
        <Paper elevation={10} className="paperStyle">
          <h3>Set Password</h3>

          <Form onSubmit={postData}>
            <TextField
              label="New Password"
              type="password"
              value={password}
              placeholder="New Password"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
            />{" "}
            <br />
            <br />
            <TextField
              label="Confirm Password"
              type="password"
              value={cpassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              required
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: "8px 0" }}
              fullWidth
            >
              Set Password
            </Button>
          </Form>
        </Paper>
      </Grid>
    </>
  );
};

export default SetForgotPassword;

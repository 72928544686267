import { axios_instance as axios } from "../../../boot/axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import MoreDetails from "./more-details";
import img from "../../../1641954871966-video-pop-up.jpg";

const ShowVenues = () => {
  const [posts, setPost] = useState([]);
  const history = useHistory();
  useEffect(async () => {
    await axios.get("/venue/venue-details").then((resp) => {
      setPost(resp.data);
    });
  }, []);
  // const moreDetails = (data) => {
  //   console.log("Venue is : " + JSON.stringify(data));
  //   <MoreDetails value={data} />;
  // };
  return (
    <>
      <br /> <br />
      <h4>
        <center>Venue Details</center>
      </h4>
      <br />
      <br />
      {posts.map((data, key) => (
        <div class="content tours-homepage">
          <div class="container">
            <div class="row">
              <div ClassName="col-xs-12 col-md-6 col-lg-4 mb-lg-0 mb-4">
                <div class="card">
                  <small class="white front">
                    <span class="far fa-clock mr-2 white"></span>
                    <strong>10: 00</strong>
                    <br />
                    days
                  </small>
                  <a class="img-card" href="#">
                    <img src={data.image} alt="image" />
                  </a>
                  <div class="card-content">
                    <p>
                      <span> {data.title} </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <h6 class="primary-color text-right">{data.price}</h6>
                    </p>
                    <br />
                    {/* <div class="special-offer ">
                                        <div class="arrow_box text-center">
                                            <span class="white subtitle bold"> 25% OFF</span>
                                        </div>
                                    </div> */}
                    <p>
                      <div class="rate">
                        <input type="radio" id="star5" name="rate" value="5" />
                        <label for="star5" title="text">
                          5 stars
                        </label>
                        <input type="radio" id="star4" name="rate" value="4" />
                        <label for="star4" title="text">
                          4 stars
                        </label>
                        <input type="radio" id="star3" name="rate" value="3" />
                        <label for="star3" title="text">
                          3 stars
                        </label>
                        <input type="radio" id="star2" name="rate" value="2" />
                        <label for="star2" title="text">
                          2 stars
                        </label>
                        <input type="radio" id="star1" name="rate" value="1" />
                        <label for="star1" title="text">
                          1 star
                        </label>
                      </div>
                      <span> Ratings </span>
                    </p>
                    <p>
                      <span> {data.vtype} </span>
                    </p>
                    <p>
                      <span> {data.address} </span>
                    </p>
                    <p>
                      <span> Seating {data.seating} / </span>
                      <span> Standing {data.standing} </span>
                    </p>
                    <div>
                      {/* <a
                        class="btn btn-primary px-3 btn-sm float-left"
                        href="#"
                        onClick={() => moreDetails(data)}
                      >
                        View Profile
                      </a> */}
                      <Link
                        class="btn btn-primary px-3 btn-sm float-left"
                        to={"/more-details/" + data._id}
                      >
                        More Details
                      </Link>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <br />
        </div>
      ))}
    </>
  );
};

export default ShowVenues;

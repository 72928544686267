import { axios_instance as axios } from "../../../../boot/axios";
import React from "react";
import { useHistory } from "react-router";
import "./header.css";

export function Header() {
  const history = useHistory();

  async function changePassword() {
    await axios
      .get("/api/auth/authenticity")
      .then((resp) => {
        if (resp.data.success) {
          history.push("/change-password");
        } else {
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function checkStatus(page) {
    await axios.get("/venue/get-status").then((resp) => {
      console.log("Response is : " + resp.data.status);
      if (resp.data.status) {
        history.push(page);
      } else {
        history.push("/venue-brand");
      }
    });
  }

  async function logout() {
    await axios
      .get("/api/auth/logout")
      .then((resp) => {
        if (resp.data.success) {
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div class="topnav">
        <a class="active" href="/">
          Home
        </a>
        <a href="#" onClick={() => checkStatus("/initial-listing")}>
          List a venue
        </a>
        <a href="/show-venues">Show Venues</a>
        <a href="#" onClick={() => checkStatus("/manage-users")}>
          Manage User
        </a>
        <a href="/venue-brand">Register Brand</a>
        <a href="/brand-details"> Brand Details</a>
        <a href="/update-venues"> Update Venue </a>
        <a href="/incoming-request">Booking Request</a>
        <a href="/forgot-password">Forgot Password</a>
        <a href="#" onClick={changePassword}>
          Change Password
        </a>
        {/* <a href="#news">Short List</a> */}
        {/* <a href="#news">Compare</a> */}
        {/* <a href="/help">Help</a> */}
        {/* <a href="/contact">Contact</a> */}
        <a href="/edit-profile"> Edit Profile</a>
        {/* <a href="/about">About</a> */}
        <a href="/login">Login</a>
        <a href="#" onClick={logout}>
          Logout
        </a>
      </div>
    </>
  );
}

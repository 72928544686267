import React, { useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Checkbox,
} from "@material-ui/core";
import "./login.css";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory } from "react-router";
import FacebookLogin from "react-facebook-login";

const Login = () => {
  const [status, setStatus] = useState(true);
  const [resp, setResp] = useState("");
  const [message, setMessage] = useState("");
  const [classStatus, setClassStatus] = useState("");
  const [styleStatus, setStyeleStatus] = useState("none");

  const history = new useHistory();

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const [email, setEmail] = useState(getCookie("email"));
  const [password, setPassword] = useState(getCookie("password"));

  function lsRememberMe() {
    if (status) {
      document.cookie = "email=" + email + ";path=http://localhost/;";
      document.cookie = "password=" + password + ";path=http://localhost/;";
    }
  }

  async function postData(e) {
    e.preventDefault();
    try {
      await axios
        .post("/api/auth/agent/login", {
          email,
          password,
        })
        .then((res) => {
          if (res.data.success) {
            setResp("Success!");
            setMessage(" Your are loggedin successfully.");
            setClassStatus("alert alert-success");
            setStyeleStatus("block");
            history.push("/agent-home");
          }
        });
    } catch (error) {
      console.log("error here: " + error);
      setResp("Error!");
      setMessage(" Email or password is incorret!");
      setClassStatus("alert alert-danger");
      setStyeleStatus("block");
    }
  }

  const responseFacebook = (response) => {
    console.log("Facebook Data : " + JSON.stringify(response));
    const accessToken = response.accessToken;
    const userID = response.userID;
    // console.log(accessToken);
    // console.log(userID);
    axios
      .post("/api/auth/facebook-login", { accessToken, userID })
      .then((resp) => {
        console.log(resp);
      });
  };

  return (
    <Grid className="gridStyle">
      <Paper
        elevation={10}
        style={{
          padding: "20px",
          height: "10%",
          width: "350px",
          margin: "250px auto",
        }}
      >
        <h3> Login</h3>
        <br />
        <FacebookLogin
          appId="2055421717958988"
          autoLoad={false}
          callback={responseFacebook}
        />
        <br />
        <br />
        <Form onSubmit={postData}>
          <div
            className={classStatus}
            id="getStatus"
            style={{ display: styleStatus }}
          >
            <strong>{resp}</strong> {message}
          </div>
          <TextField
            label="Enter email"
            type="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Enter password"
            type="password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
          <br />

          <label for="rememberMe">Remember me</label>
          <Checkbox
            id="rememberMe"
            onClick={lsRememberMe}
            onChange={() => setStatus(!status)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: "8px 0" }}
            fullWidth
          >
            Login
          </Button>
          <br />
        </Form>
        <Typography>
          Do you have an account ? <Link to="/signup"> Signup</Link>
        </Typography>

        <Typography>
          Forgot password ? <Link to="/forgot-password"> Click here</Link>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Login;

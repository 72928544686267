import React from "react";
import "./header.css";
import { useHistory } from "react-router";
import { axios_instance as axios } from "../../../../boot/axios";
import { Link } from "react-router-dom";
const Header = () => {
  const history = useHistory();

  async function changePassword() {
    await axios
      .get("/api/auth/authenticity")
      .then((resp) => {
        if (resp.data.success) {
          history.push("/change-password");
        } else {
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function logout() {
    await axios
      .get("/api/auth/logout")
      .then((resp) => {
        if (resp.data.success) {
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div id="wrapper-navbar">
        <nav
          id="top"
          class="navbar py-3 fixed-top navbar-expand-lg navbar-light bg-light"
        >
          <Link class="navbar-brand ml-sm-5" to={"#"}>
            <img src="assets/images/logo.png" alt="image" />
          </Link>
          <button
            class="navbar-toggler collapsed navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
            <span class="sr-only">Toggle navigation</span>
          </button>
          <div
            class="collapse navbar-collapse ml-auto"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto">
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"/home"}
                role="button"
              >
                Home
              </Link>
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"/explore-venues"}
                role="button"
              >
                Explore Venue
              </Link>
              {/* <a
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                href="/venue-booking"
                role="button"
              >
                Book Venue
              </a> */}
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"#"}
                role="button"
              >
                Short List
              </Link>
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"#"}
                role="button"
              >
                Compare
              </Link>
              <li class="nav-item dropdown">
                <Link
                  class="nav-link dropdown-toggle dropdown-menu-right  ml-lg-0 ml-3 mr-4 my-lg-0 my-2 lastitem"
                  to={"#"}
                  id="navbarDropdown6"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Account Settings
                </Link>
                <div class="dropdown-divider d-lg-none"></div>
                <div
                  class="dropdown-menu dropdownId dropdown-menu-right"
                  aria-labelledby="navbarDropdown6"
                >
                  <Link class="dropdown-item mt-1" to={"/edit-profile"}>
                    Edit Profile
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link class="dropdown-item" to={"#"} onClick={changePassword}>
                    Change Password
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link class="dropdown-item mb-1" to={"/contact"}>
                    Contact Us
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link class="dropdown-item mb-1" to={"/about"}>
                    About Us
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link class="dropdown-item mb-1" to={"/our-team"}>
                    Our Team
                  </Link>
                  <div class="dropdown-divider"></div>
                  <Link class="dropdown-item mb-1" to={"/help"}>
                    Help
                  </Link>
                </div>
              </li>
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"/login"}
                role="button"
              >
                Login
              </Link>
              <Link
                class="nav-link  mr-3 open my-lg-0 my-2 ml-lg-0 ml-3"
                to={"#"}
                onClick={logout}
                role="button"
              >
                Logout
              </Link>
            </ul>

            <div class="row d-none d-lg-block sidebartop">
              <div class="col-12 mr-4 sidebar">
                <div class="mini-submenu">
                  <span class="icon-bar2"></span>
                  <span class="icon-bar2"></span>
                  <span class="icon-bar2"></span>
                </div>
                <div class="list-group">
                  <span class="list-group-item active">
                    <img
                      class="svgcenter mt-4 logo-light"
                      src="assets/svgs/logolight.svg"
                      alt="image"
                    />
                    <span class="pull-right" id="slide-submenu">
                      <i class="fa fa-times"></i>
                    </span>
                  </span>
                  <p class="white py-4 px-5 text-center  list-group-item light">
                    Lorem ipsum diocritatem eu, fierent molestie petentium id
                    his. Ut aeterno nostrum nam, solet sapientem ea quo. Cum te
                    meis illud, aeterno accusata ut vix.
                  </p>
                  <ul class="list-group-item py-4">
                    <li>
                      <h5 class="white text-center">
                        <i class="white fas fa-map-marker-alt mr-2"></i>Mave
                        Avenue, New York
                      </h5>
                    </li>
                    <li>
                      <h5 class="white text-center">
                        <i class="white fas fa-phone-square mr-2"></i>United
                        States (+1) 3333.1111
                      </h5>
                    </li>
                    <li>
                      <h5 class="white text-center">
                        <i class="white fas fa-envelope mr-2"></i>
                        hello@ourcompany.com
                      </h5>
                    </li>
                  </ul>
                  <div class="list-group-item text-center pt-4 ">
                    <h6>Follow Us</h6>
                    <ul class="text-center py-3">
                      <li class="list-inline-item">
                        <Link to={"http://www.facebook.com"}>
                          <i class="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li class="list-inline-item">
                        <Link to={"http://www.twitter.com"}>
                          <i class="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li class="list-inline-item">
                        <Link to={"http://www.instagram.com"}>
                          <i class="fab fa-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                    <div class="list-group-item py-4">
                      <Link to={"#"} class="d-block white py-2">
                        <i class="fa fa-users"></i> About Us
                      </Link>
                      <Link class="white d-block" to={"#"}>
                        <i class="fa fa-envelope"></i> Contact Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;

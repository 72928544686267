import React, { useEffect, useState } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import { useHistory } from "react-router";

const BrandDetails = () => {
  const [post, setPost] = useState([]);
  const history = useHistory();
  useEffect(async () => {
    await axios
      .get("/venue/brand-details")
      .then((response) => {
        setPost(response.data);
        console.log("Data is : " + response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <h4> Brand Details page </h4>
      <p>{post.name} </p>
      <p> {post.email}</p>
      <p> {post.logo} </p>
      <p> {post.address} </p>
      <p> {post.phone}</p>
      <button
        onClick={() =>
          history.push({
            pathname: "/update-brand",
            post,
          })
        }
      >
        Update Brand
      </button>
    </>
  );
};

export default BrandDetails;

import "./explore-venue.css";
import React, { useEffect, useState, useRef } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import Explore from "./explore-venue";
import { fetchCountries, fetchCities, fetchAreas } from "./location";
import "react-multiple-select-dropdown-lite/dist/index.css";
import Select from "react-select";
import Header from "../home/header/header";
import Footer from "../home/footer/footer";
import { useHistory, useLocation } from "react-router";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Button, PopoverHeader, PopoverBody } from "reactstrap";

// import PopoverHeader from "react-bootstrap/PopoverHeader";

const Filters = () => {
  const location = useLocation();
  const [searchCity, setSearchCity] = useState(location.city);
  const [eventType, setEventType] = useState(location.etype);
  const [venueType, setVenueType] = useState(location.vtype);
  const [post, setPosts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState([]);
  const [vtype, setVtype] = useState("");
  const [etype, setEtype] = useState("");
  const [event, setEvent] = useState([]);
  const [food, setFood] = useState([]);
  const [facility, setFacility] = useState([]);
  const [capacity, setCapacity] = useState("");
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  // const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const vType = [
    { label: "Hotel", value: "Hotel" },
    { label: "Resort/Club", value: "Resort/Club" },
    { label: "Banquet Hall", value: "Banquet Hall" },
    { label: "Beach House", value: "Beach House" },
    { label: "Farm House", value: "Farm House" },
    { label: "Sports Ground", value: "Sports Ground" },
    { label: "Art Gallery", value: "Art Gallery" },
    { label: "Historical Building", value: "Historical Building" },
    { label: "Exhibition Hall", value: "Exhibition Hall" },
    { label: "Auditorium/Theater", value: "Auditorium/Theater" },
    { label: "Mall", value: "Mall" },
    { label: "Cafe/Restaurant", value: "Cafe/Restaurant" },
    { label: "Meeting/Training", value: "Meeting/Training" },
    { label: "Outdoor", value: "Outdoor" },
  ];

  const eType = [
    {
      label: "Corporate",
      value: "Corporate",
    },
    {
      label: "Weddings",
      value: "Weddings",
    },
    {
      label: "Party",
      value: "Party",
    },
    {
      label: "Lifestyle",
      value: "Lifestyle",
    },
  ];

  const eData = {
    Corporate: [
      {
        label: "Annual Dinner",
        value: "Annual Dinner",
      },
      {
        label: "BTL Activation",
        value: "BTL Activation",
      },
      {
        label: "Business Meeting",
        value: "Business Meeting",
      },
      {
        label: "Conference/Seminar",
        value: "Conference/Seminar",
      },
      {
        label: "Networking Event",
        value: "Networking Event",
      },
      {
        label: "Training Workshop",
        value: "Training Workshop",
      },
      {
        label: "Product Launch",
        value: "Product Launch",
      },
      {
        label: "Exhibition",
        value: "Exhibition",
      },
      {
        label: "Media Event",
        value: "Media Event",
      },
      {
        label: "Bloggers Meet",
        value: "Bloggers Meet",
      },
      {
        label: "Day Out",
        value: "Day Out",
      },
    ],
    Weddings: [
      { label: "Engagement", value: "Engagement" },
      { label: "Nikkah Reception", value: "Nikkah Reception" },
      { label: "Mehndi", value: "Mehndi" },
      { label: "Barat", value: "Barat" },
      { label: "Valima Reception", value: "Valima Reception" },
    ],
    Party: [
      { label: "Birthday", value: "Birthday" },
      { label: "Special Celebration", value: "Special Celebration" },
      { label: "New Year", value: "New Year" },
      { label: "Graduation", value: "Graduation" },
      { label: "Anniversary", value: "Anniversary" },
      { label: "Weekend Brunch", value: "Weekend Brunch" },
      { label: "Bridal Shower", value: "Bridal Shower" },
      { label: "Private Dinning", value: "Private Dinning" },
      { label: "Iftar", value: "Iftar" },
    ],
    Lifestyle: [
      { label: "Concert", value: "Concert" },
      { label: "Award Show", value: "Award Show" },
      { label: "Music Gig", value: "Music Gig" },
      { label: "Fashion Show", value: "Fashion Show" },
      { label: "Festival", value: "Festival" },
    ],
  };

  const Foods = [
    { labe: "External Catering Allowed", value: "External Catering Allowed" },
    { label: "In house Catering", value: "In house Catering" },
    { label: "Caterers on Panel", value: "Caterers on Panel" },
    {
      label: "In house Soft Drinks and Mineral Water",
      value: "In house Soft Drinks and Mineral Water",
    },
    { label: "Dedicated Pantry Area", value: "Dedicated Pantry Area" },
    {
      label: "Dedicated Area for Live Cooking",
      value: "Dedicated Area for Live Cooking",
    },
  ];
  const Facilities = [
    { label: "Parking/Valet", value: "Parking/Valet" },
    { label: "Disabled Access", value: "Disabled Access" },
    { label: "Security", value: "Security" },
    { label: "Air Conditioned", value: "Air Conditioned" },
    { label: "Wi-Fi", value: "Wi-Fi" },
    { label: "Bridal Room", value: "Bridal Room" },
    { label: "Multimedia", value: "Multimedia" },
    { label: "Restrooms", value: "Restrooms" },
    { label: "Privacy", value: "Privacy" },
    { label: "Segregation", value: "Segregation" },
  ];

  const Capacity = [
    { label: "Seating", value: "Seating" },
    { label: "Standing", value: "Standing" },
  ];

  useEffect(() => {
    let temp = [];
    fetchCountries().then((countries) => {
      countries.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setCountries(temp);
    });
  }, []);

  useEffect(() => {
    fetchCities(country).then((cities) => {
      let temp = [];
      setAreas([]);
      cities.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setCities(temp);
    });
  }, [country]);

  useEffect(() => {
    let temp = [];
    fetchAreas(city).then((areas) => {
      areas.forEach((element) => {
        temp.push({ label: element, value: element });
      });
      setAreas(temp);
    });
  }, [city]);

  const reset = () => {
    setArea([]);
    setCity("");
  };

  const handleCountrySelect = (e) => {
    setCountry(e.value);
  };

  const handleCitySelect = (e) => {
    setCity(e.value);
  };

  const handleAreaSelect = (e) => {
    setArea(e.map((e) => e.value));
  };

  const handleVtypeSelect = (e) => {
    setVtype(e.map((e) => e.value));
  };

  const handleEtypeSelect = (e) => {
    setEtype(e.value);
  };

  const handleEventSelect = (e) => {
    setEvent(e.map((e) => e.value));
  };

  const handleFoodsSelect = (e) => {
    setFood(e.map((e) => e.value));
  };

  const handleFacilitiesSelect = (e) => {
    setFacility(e.map((e) => e.value));
  };

  const handleCapacitySelect = (e) => {
    setCapacity(e.value);
  };

  const handleMinCapacity = (e) => {
    setMin(e.target.value);
  };

  const handleMaxCapacity = (e) => {
    setMax(e.target.value);
  };

  const handleMinPrice = (e) => {
    setMinPrice(e.target.value);
  };

  const handleMaxPrice = (e) => {
    setMaxPrice(e.target.value);
  };

  useEffect(async () => {
    console.log(
      "City : " +
        searchCity +
        ", Venue : " +
        venueType +
        ", Event : " +
        eventType
    );
    await axios
      .post("/venue/explore-venues", { searchCity, venueType, eventType })
      .then((response) => {
        console.log("Explore Venue");
        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function postLocation(e) {
    e.preventDefault();
    await axios
      .post("/venue/location", { country, city, area })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postVenueType(e) {
    e.preventDefault();
    await axios
      .post("/venue/venue-type", { vtype })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postEventType(e) {
    e.preventDefault();
    await axios
      .post("/venue/event-type", { etype, event })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postFacilities(e) {
    e.preventDefault();
    axios
      .post("/venue/food-facility", { food, facility })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => console.log(err));
  }

  async function postCapacity(e) {
    e.preventDefault();
    axios
      .post("/venue/venue-capacity", { capacity, min, max })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postPrice(e) {
    e.preventDefault();
    axios
      .post("/venue/venue-price", { minPrice, maxPrice })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postData(e) {
    e.preventDefault();
    axios
      .post("/venue/venue-filter", {
        country,
        city,
        area,
        vtype,
        etype,
        event,
        food,
        facility,
      })
      .then((resp) => {
        setPosts(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(async () => {
    setPosts([]);
    const arr = [];
    post.forEach((venue) => {
      if (venue.country == country) {
        arr.push(venue);
      }
    });
    setPosts([]);
  }, []);

  return (
    <>
      <Header />
      <br /> <br />
      <section id="section3" class="tour-list-sidebar tour-list-sidebar-2-col">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6  col-lg-3 ml-lg-5 order-lg-first order-last mt-3 mt-lg-0">
              <div class="form-container py-3">
                <h4 class="black bold mt-3 px-4 pb-2 text-center">
                  Filter Venues
                </h4>
                <form id="sidebar-form" class="px-xl-5 px-lg-3 px-4">
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Location
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postLocation}>
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Country"
                                    options={countries}
                                    onChange={(e) => {
                                      handleCountrySelect(e);
                                      reset();
                                    }}
                                  />
                                  <br />
                                  <Select
                                    className="width-selector"
                                    placeholder="Select City"
                                    options={cities}
                                    value={
                                      city == ""
                                        ? ""
                                        : { label: city, value: city }
                                    }
                                    onChange={(e) => {
                                      handleCitySelect(e);
                                      setArea([]);
                                    }}
                                  />
                                  <br />
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Area"
                                    options={areas}
                                    isMulti
                                    value={
                                      area == []
                                        ? []
                                        : area.map((arr) => ({
                                            label: arr,
                                            value: arr,
                                          }))
                                    }
                                    onChange={handleAreaSelect}
                                  />

                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Location
                          </Button>
                        </OverlayTrigger>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="fa fa-compass" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Venue Type
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postVenueType}>
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Venue Type"
                                    options={vType}
                                    isMulti
                                    // value={area}
                                    onChange={handleVtypeSelect}
                                  />
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Venue Type
                          </Button>
                        </OverlayTrigger>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="fas fa-warehouse"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Event Type
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postEventType}>
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Event Type"
                                    options={eType}
                                    onChange={(e) => {
                                      setEvent([]);
                                      handleEtypeSelect(e);
                                    }}
                                  />
                                  <br />
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Event"
                                    options={eData[etype]}
                                    value={
                                      event == []
                                        ? []
                                        : event.map((arr) => ({
                                            label: arr,
                                            value: arr,
                                          }))
                                    }
                                    isMulti
                                    onChange={handleEventSelect}
                                  />
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Event Type
                          </Button>
                        </OverlayTrigger>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="fas fa-door-open"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Facilities
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postFacilities}>
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Foods"
                                    options={Foods}
                                    isMulti
                                    onChange={handleFoodsSelect}
                                  />
                                  <br />
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Facilities"
                                    options={Facilities}
                                    isMulti
                                    onChange={handleFacilitiesSelect}
                                  />
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Facilities
                          </Button>
                        </OverlayTrigger>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="fas fa-utensils"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Capacity
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postCapacity}>
                                  <Select
                                    className="width-selector"
                                    placeholder="Select Layout Style"
                                    options={Capacity}
                                    onChange={handleCapacitySelect}
                                  />
                                  <br />
                                  <input
                                    type="number"
                                    className="width-selector"
                                    placeholder="Min"
                                    onChange={handleMinCapacity}
                                  />
                                  <br />
                                  <input
                                    type="number"
                                    className="width-selector"
                                    placeholder="Max"
                                    onChange={handleMaxCapacity}
                                  />
                                  <br />
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Capacity
                          </Button>
                        </OverlayTrigger>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="fas fa-calendar"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="input-group">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <PopoverHeader as="h3">
                                Filter by Price
                              </PopoverHeader>
                              <PopoverBody>
                                <form onSubmit={postPrice}>
                                  <input
                                    type="number"
                                    placeholder="Min"
                                    className="width-selector"
                                    onChange={handleMinPrice}
                                  />
                                  <br />
                                  <input
                                    type="number"
                                    placeholder="Max"
                                    className="width-selector"
                                    onChange={handleMaxPrice}
                                  />
                                  <br />
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    style={{ width: "80px" }}
                                  >
                                    Filter
                                  </Button>
                                </form>
                              </PopoverBody>
                            </Popover>
                          }
                        >
                          <Button
                            className="form-control  clr input-group-text"
                            variant="success"
                          >
                            Price
                          </Button>
                        </OverlayTrigger>
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <i class="fas fa-coins"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-12">
                      <button
                        type="submit"
                        class="btn col-sm-12 my-2 btn-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="more-info mx-auto my-4">
                <h6 class="black semibold text-center mx-4 mt-3 mb-3 info-title">
                  Quick Contact
                </h6>
                <div class="pb-2">
                  <a href="tel:+923003926373">
                    <h5 class="grey text-center tel-info">
                      <i class="fas primary-color fa-phone faa-tada animated mr-2 grey my-lg-0 mb-1"></i>
                      (+92) 300-3926373
                    </h5>
                  </a>
                  <a href="mailto:hello@ourcompany.com">
                    <h5 class="grey text-center mail-info">
                      <i class="fas fa-envelope faa-horizontal animated primary-color mr-2"></i>
                      contact@venuecity.com
                    </h5>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-6 col-lg-4">
              <Explore posts={post} />
              <div class="row mb-lg-0 mb-4 float-right">
                <div class="col-12 mt-3">
                  <div class="pagination">
                    <div class="current">
                      <h6>1</h6>
                    </div>
                    <a class="" href="#">
                      <h6>2</h6>
                    </a>
                    <a href="#">
                      <h6>3</h6>
                    </a>
                    <a href="#">
                      <h6 class="arrow-offset">›</h6>
                    </a>
                    <a href="#">
                      <h6 class="arrow-offset">»</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Filters;

import React from "react";
import Header from "./header/header";
import Body from "./body/body";
import Footer from "./footer/footer";

const Home = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  );
};

export default Home;

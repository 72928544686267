import { axios_instance as axios } from "../../../boot/axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BarLoader } from "react-spinners";

const BookingReport = () => {
  const [loading, setLoading] = useState(false);

  const [booking, setBooking] = useState([]);
  const location = useLocation();
  const id = location.id;

  useEffect(async () => {
    await axios.post("/booking/booking-report", { id }).then((resp) => {
      setBooking(resp.data);
      setLoading(true);
    });
  }, []);
  //   console.log(booking);
  return (
    <>
      <h4> Generate Booking report</h4>
      <br />
      <br />
      {loading ? (
        <>
          <h5> Venue Details</h5> <br />
          <p> {booking.venueId.title} </p>
          {/* <p value={booking.venueId.title}> sfsd</p> */}
          <p> {booking.venueId.vtype} </p>
          <br />
          <br />
          <h5> Client Details</h5> <br />
          <p> {booking.userId.name} </p>
          <p> {booking.userId.email} </p>
          <p> {booking.userId.phone} </p>
          <br />
          <br />
          <h5> Event Details</h5> <br />
          <p> Time In : {booking.from} </p>
          <p> Time Out : {booking.to} </p>
          <p> Date : {booking.date} </p>
          <p> Total Budget : {booking.budget} </p>
          <p> Total Guests : {booking.guest} </p>
          {/* <input value={booking.from} />
        <input value={booking.from} /> */}
        </>
      ) : (
        <BarLoader loading />
      )}
    </>
  );
};

export default BookingReport;

import React, { useState } from "react";
import { useLocation, useHistory } from "react-router";
import { axios_instance as axios } from "../../../boot/axios";
import Checkbox from "./check-box";

const FacilitySafety = () => {
  const [facilityItems, setFacilityItems] = useState({});
  const [safetyItems, setSafetyItems] = useState({});
  const location = new useLocation();
  const history = new useHistory();

  const Facilities = [
    { name: "Parking/Valet", key: "checkBox1", value: "Parking/Valet" },
    { name: "Disabled Access", key: "checkBox2", value: "Disabled Access" },
    { name: "Security", key: "checkBox3", value: "Security" },
    { name: "Air Conditioned", key: "checkBox4", value: "Air Conditioned" },
    { name: "Wi-Fi", key: "checkBox5", value: "Wi-Fi" },
    { name: "Bridal Room", key: "checkBox6", value: "Bridal Room" },
    { name: "Multimedia", key: "checkBox7", value: "Multimedia" },
    { name: "Restrooms", key: "checkBox8", value: "Restrooms" },
    { name: "Privacy", key: "checkBox9", value: "Privacy" },
    { name: "Segregation", key: "checkBox10", value: "Segregation" },
  ];

  const Safety = [
    { name: "Emergency Exit", key: "checkBox1", value: "Emergency Exit" },
    { name: "Fire Extinguisher", key: "checkBox2", value: "Fire Extinguisher" },
    { name: "Smoke Detector", key: "checkBox3", value: "Smoke Detector" },
    { name: "First Aid Kid", key: "checkBox4", value: "First Aid Kid" },
    { name: "Standby Ambulance", key: "checkBox5", value: "Standby Ambulance" },
  ];

  const facilityHandleChange = (event) => {
    setFacilityItems({
      ...facilityItems,
      [event.target.name]: event.target.checked,
    });
  };

  const safetyHandleChange = (event) => {
    setSafetyItems({
      ...safetyItems,
      [event.target.name]: event.target.checked,
    });
  };

  async function postData(e) {
    e.preventDefault();
    const fields = location.state;
    const data = { ...fields, facilityItems, safetyItems };
    console.log("Facility and Safety : " + JSON.stringify(data));
    await axios
      .post("/venue/venue-listing", data)
      .then((response) => {
        if (response.data.success) {
          history.push("/agent-home");
        }
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
      <h3> Facility and Safety </h3> <br />
      <br />
      <form onSubmit={postData}>
        <h6>Facilities</h6>
        {Facilities.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={facilityItems[item.name]}
              onChange={facilityHandleChange}
            />
            <br />
          </label>
        ))}

        <h6>Safety</h6>
        {Safety.map((item) => (
          <label key={item.key}>
            {item.name}
            <Checkbox
              name={item.name}
              checked={safetyItems[item.name]}
              onChange={safetyHandleChange}
            />
            <br />
          </label>
        ))}
        <button type="submit"> Save and Finish </button>
      </form>
    </>
  );
};

export default FacilitySafety;

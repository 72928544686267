import React, { useEffect, useState } from "react";
import { axios_instance as axios } from "../../../boot/axios";
import { useLocation } from "react-router";
import { BarLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const VenueBooking = () => {
  const location = useLocation();
  const [post, setpost] = useState([]);
  const [id, setId] = useState(location.bookingId);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // console.log("Id is : " + id);
  // setId();

  useEffect(() => {
    // setId();
    async function BookedVenues() {
      console.log("Id : " + id);
      await axios
        .get("/booking/booked-venue/" + id)
        .then((resp) => {
          console.log("Data is : " + JSON.stringify(resp));
          setpost(resp.data);
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    BookedVenues();
  }, []);

  const BookingDetails = () => {
    // console.log("Auto  Id : " + id)
    history.push({
      pathname: "/booking-details",
      id,
    });
  };
  return (
    <>
      {loading ? (
        <>
          <h5>
            <center> Booked Venue</center>
          </h5>
          {/* {post.map((data, key) => ( */}
          <div class="content tours-homepage">
            <div class="container">
              <div class="row">
                <div ClassName="col-xs-12 col-md-6 col-lg-4 mb-lg-0 mb-4">
                  <div class="card">
                    <small class="white front">
                      <span class="far fa-clock mr-2 white"></span>
                      <strong>10: 00</strong>
                      <br />
                      days
                    </small>
                    <a class="img-card" href="#">
                      <img src="#" alt="image" />
                    </a>
                    <div class="card-content">
                      <p>
                        <span> {post.venueId.title} </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <h6 class="primary-color text-right">
                          {post.venueId.price}
                        </h6>
                      </p>
                      <br />
                      {/* <div class="special-offer ">
                                        <div class="arrow_box text-center">
                                            <span class="white subtitle bold"> 25% OFF</span>
                                        </div>
                                    </div> */}
                      <p>
                        <div class="rate">
                          <input
                            type="radio"
                            id="star5"
                            name="rate"
                            value="5"
                          />
                          <label for="star5" title="text">
                            5 stars
                          </label>
                          <input
                            type="radio"
                            id="star4"
                            name="rate"
                            value="4"
                          />
                          <label for="star4" title="text">
                            4 stars
                          </label>
                          <input
                            type="radio"
                            id="star3"
                            name="rate"
                            value="3"
                          />
                          <label for="star3" title="text">
                            3 stars
                          </label>
                          <input
                            type="radio"
                            id="star2"
                            name="rate"
                            value="2"
                          />
                          <label for="star2" title="text">
                            2 stars
                          </label>
                          <input
                            type="radio"
                            id="star1"
                            name="rate"
                            value="1"
                          />
                          <label for="star1" title="text">
                            1 star
                          </label>
                        </div>
                        <span> Ratings </span>
                      </p>
                      <p>
                        <span> {post.venueId.vtype} </span>
                      </p>
                      <p>
                        <span> {post.venueId.address} </span>
                      </p>
                      <p>
                        <span> Seating {post.venueId.seating} / </span>
                        <span> Standing {post.venueId.standing} </span>
                      </p>
                      <div>
                        <h6> Booking Details</h6>
                        <h7> Date : {post.date}</h7>
                        <h7> From : {post.from}</h7>
                        <h7> To : {post.to}</h7>
                        {/* <Link
                        class="btn btn-primary px-3 btn-sm float-left"
                        to={"/venue-profile/" + data._id}
                      >
                        View Profile
                      </Link> */}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          {/* ))} */}
          {/* <a href="/explore-venues" class="btn btn-primary px-3 btn-sm float-left">
        Add More Venues
      </a> */}
          <a
            href="#"
            onClick={BookingDetails}
            class="btn btn-primary px-3 btn-sm float-left"
          >
            Next
          </a>

          {/* <Link
            class="btn btn-primary px-3 btn-sm float-left"
            to={"/booking-details"}
          >
            Next
          </Link> */}
        </>
      ) : (
        <BarLoader loading />
      )}
    </>
  );
};

export default VenueBooking;

import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../signup/signup.css";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { axios_instance as axios } from "../../../boot/axios";

const VenueBrand = () => {
  const history = useHistory();
  const [logo, setLogo] = useState(null);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    website: "",
  });

  useEffect(async () => {
    let info = "";
    await axios.get("/api/auth/get-email").then((resp) => {
      // console.log(JSON.stringify(resp));
      if (resp.data.success) {
        info = resp.data.email;
      }
    });
    setData({ email: info });
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setLogo(event.target.files[0]);
  };

  async function postData(e) {
    e.preventDefault();
    const name = data.name;
    const email = data.email;
    const phone = data.phone;
    const address = data.address;
    const website = data.website;
    let formData = new FormData();
    formData.append("file", logo);
    try {
      await axios
        .post("/venue/save-file", formData, {
          headers: { "Content-type": "multipart/form-data" },
        })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    const path = logo.name;
    // console.log("Date : " + Date.now());
    try {
      await axios
        .post("/venue/venue-brand", {
          name,
          email,
          phone,
          address,
          website,
          path,
        })
        .then(() => {
          history.push("/agent-home");
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid className="gridStyle">
      <Paper elevation={10} className="paperStyle">
        <h3> Register Brand</h3>
        <Form onSubmit={postData}>
          <TextField
            label="Enter brand name"
            type="text"
            placeholder="Enter brand name"
            name="name"
            value={data.name}
            onChange={inputEvent}
            fullWidth
            required
          />{" "}
          <br />
          <br />
          <TextField
            label="Enter email"
            type="email"
            placeholder="Enter email"
            name="email"
            value={data.email}
            onChange={inputEvent}
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            label="Enter phone"
            type="tel"
            placeholder="Enter phone"
            name="phone"
            value={data.phone}
            onChange={inputEvent}
            fullWidth
          />
          <br />
          <br />
          <TextField
            label="Enter address"
            type="text"
            placeholder="Enter address"
            name="address"
            value={data.address}
            onChange={inputEvent}
            fullWidth
          />{" "}
          <br />
          <br />
          <TextField
            label="Enter website url"
            type="url"
            placeholder="Enter website url"
            name="website"
            value={data.website}
            onChange={inputEvent}
            fullWidth
          />
          <br />
          <input type="file" name="file" onChange={onChangeHandler} />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox name="terms" color="primary" />}
            label="I accept all terms and conditions."
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: "8px 0" }}
            fullWidth
          >
            Register
          </Button>{" "}
          <br />
          <Typography>
            {" "}
            Do you have an account ? <Link to="/agent-login"> Login</Link>{" "}
          </Typography>
        </Form>
      </Paper>
    </Grid>
  );
};

export default VenueBrand;

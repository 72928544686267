import Vue from "vue";
import axios from "axios";

const axios_instance = axios.create({
  baseURL: "",
});

Vue.prototype.$axios = axios_instance;

export { axios_instance };
